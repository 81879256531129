import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../src/Pages/SideMenu";
import Header from "../../src/Pages/Header";
import Footer from "../../src/Pages/Footer";
import Logout from "../../src/Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../src/Utilities/Loader";
import { USER_PROFILE } from "../Utils";
import moment from "moment";
import {
  getMasters,
  newRqeuestAcceptReject,
  newRqeuest,
  rmGetmasters,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2';
import Select from "react-select";

const NewRequest = (category, index) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [estDate, setEstDate] = useState("");
  const [corePermit, setCorePermit] = useState('');
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getAccName, setGetAccName] = useState([]);
  const [submitResponse, setsubmitResponse] = useState("");
  const [showMsg, setShowMsg] = useState("false");
  const [errEstimated, setErrEstimated] = useState("");
  const [errRemark, setErrRemark] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [code, setCode] = useState([]);
  const [mobileNo, setMobileNo] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [rejectedRequests, setRejectedRequests] = useState("");
  const [errors, setErrors] = useState({});
  const [technicianMastersData, setTechnicianMastersData] = useState([])
  const [selectedTechnicians, setSelectedTechnicians] = useState({});
  const [technicianShiftId, setTechnicianShiftId] = useState();
  const [technicianShiftName, setTechnicianShiftName] = useState();

  //Validations
  const [errRemarks, setErrRemarks] = useState([]); // Array to track error messages for each index
  const [errDate, setErrDate] = useState([]); // Array to track date errors for each index
  const [errorsTechnician, setErrorsTechnician] = useState({});

  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );


  const handleRadioChange = (index, value) => {
    // Set corePermit value for the index
    setCorePermit((prev) => ({
      ...prev,
      [index]: value, // Update specific index
    }));

    // Update the corePermitRequired in the data array
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        corePermitRequired: value, // Set value in data
      };
      return updatedData;
    });

    // Clear any errors related to corePermit for the specific index
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index]?.corePermit; // Remove error for this index
      return updatedErrors;
    });
  };

  // const handleEditClick = (index) => {
  //   setIsEditMode(true);
  //   setSelectedIndex(index);
  // };



  // const handleRemark = (e, index) => {
  //   if (data[index]) {
  //     const updatedData = [...data];
  //     updatedData[index].accepterRemarks = e.target.value;
  //     setData(updatedData);
  //     setErrRemark("");
  //   }
  // };

  // const handleRemark = (e, index) => {
  //   const updatedData = [...data];
  //   updatedData[index].accepterRemarks = e.target.value;
  //   setData(updatedData);
  //   setErrRemark("");
  // };


  const handleresponse = () => {
    setShowResponsePopup(true);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const getMasterData = async () => {
    const getData = rmGetmasters;
    const headers = await GetApiHeaders();
    try {
      const responseGetData = await axios.get(getData, { headers });
      console.log("resssss,", responseGetData);
      setTechnicianMastersData(responseGetData.data.response.technicianRolesList);
      setGetData(responseGetData.data.response.employees);
    } catch (err) {
      console.log("getError", err);
    }
  };

  const handleTechnicianSelect = (selectedValue, subLocationId, locationId) => {
    console.log("selectedTechnician", selectedValue);

    // Update the selected technicians state correctly
    setSelectedTechnicians((prevState) => ({
      ...prevState,
      [`${locationId}-${subLocationId}`]: selectedValue,
    }));

    // Set technician shift values
    setTechnicianShiftName(selectedValue?.label || "");
    setTechnicianShiftId(selectedValue?.value || "");

    // Clear error after selection
    const technicianKey = `${locationId}-${subLocationId}`;
    setErrorsTechnician((prevErrors) => ({
      ...prevErrors,
      [technicianKey]: "", // Clear the technician error if selected
    }));
  };




  const handleReject = async (index) => {
    const remarks = data[index]?.accepterRemarks;
    let hasError = false;
    const updatedRemarksErrors = [...errRemarks];
    // const updatedTechnicianErrors = { ...errorsTechnician }; // Copy of technician errors


    const approveApi = newRqeuestAcceptReject;
    const headers = await GetApiHeaders();

    if (!remarks || remarks.trim() === "") {
      updatedRemarksErrors[index] = "Please Enter Remarks";
      hasError = true;
    } else {
      updatedRemarksErrors[index] = ""; // Clear error if remarks are valid
    }

    // Technician validation
    // const technicianKey = `${data[index]?.locationId}-${data[index]?.subLocationId}`;
    // if (!selectedTechnicians[technicianKey]) {
    //   updatedTechnicianErrors[technicianKey] = "Please Select a Technician"; // Set error if technician is not selected
    //   hasError = true;
    // } else {
    //   updatedTechnicianErrors[technicianKey] = ""; // Clear error if technician is selected
    // }


    setErrRemarks(updatedRemarksErrors);
    // setErrorsTechnician(updatedTechnicianErrors); // Update technician errors

    if (hasError) return;


    const postData = {
      acceptorName: `${getAccName.firstName} ${getAccName.lastName}`,
      acceptorID: getAccName.userId,
      acceptorMobileNumber: getAccName.mobileNumber,
      serverRecordId: data[index].serverRecordId,
      requestId: data[index].requestId,
      requesterName: data[index].requesterName,
      requesterMobileNumber: data[index].requesterMobileNumber,
      requesterType: data[index].requesterType,
      typeOfWork: data[index].typeOfWork,
      typeOfWorkId: data[index].typeOfWorkId,
      function: data[index].function,
      functionId: data[index].functionId,
      location: data[index].location,
      locationId: data[index].locationId,
      subLocation: data[index].subLocation,
      subLocationId: data[index].subLocationId,
      requiredDate: data[index].requiredDate,
      image: "",
      requesterRemarks: data[index].requesterRemarks,
      accepterRemarks: remarks,
      corePermitRequired: corePermit,
      estimatedCompletionDate: estDate,
      technicianRemarks: data[index].technicianRemarks,
      // technicianShiftId: technicianShiftId,
      // technicianShiftName: technicianShiftName,
      technicianShiftId: "0",
      technicianShiftName: "",
      status: "Rejected",
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
    };

    try {
      setIsLoading(true);
      const response = await axios.post(approveApi, postData, { headers });
      if (response.data.statusCode == 200 || response.data.statusCode == 500) {
        setShowResponsePopup(true);
        setsubmitResponse(response.data.message);
        setRejectedRequests((prev) => [...prev, index]);
        window.location.reload();
      }
    } catch (err) {
      console.error("error", err);
    } finally {
      setIsLoading(false);
    }
  };



  const handleAprove = async (index) => {
    console.log("Validation check starting for index:", index);

    console.log("technicianShiftName data", technicianShiftId, technicianShiftName);
    const remarks = data[index]?.accepterRemarks;
    const date = data[index]?.estimatedCompletionDate || "";
    const corePermit = data[index]?.corePermitRequired;

    // Validation for remarks, date, and corePermitRequired
    let hasError = false;
    const updatedErrors = { ...errors }; // Make a copy of the errors object
    const updatedRemarksErrors = [...errRemarks];
    const updatedDateErrors = [...errDate];
    const updatedTechnicianErrors = { ...errorsTechnician }; // Copy of technician errors

    // Remarks validation
    if (!remarks || remarks.trim() === "") {
      updatedRemarksErrors[index] = "Please Enter Remarks";
      hasError = true;
    } else {
      updatedRemarksErrors[index] = ""; // Clear error if remarks are valid
    }

    // Date validation
    if (!date) {
      updatedDateErrors[index] = "Please Select a Date";
      hasError = true;
    } else {
      updatedDateErrors[index] = ""; // Clear error if the date is valid
    }

    // corePermitRequired validation
    if (!corePermit) {
      updatedErrors[index] = { corePermit: "Please Select Yes or No" }; // Set error for corePermitRequired at the specific index
      hasError = true;
    } else {
      updatedErrors[index] = {}; // Clear error if corePermitRequired is valid
    }

    // Technician validation
    const technicianKey = `${data[index]?.locationId}-${data[index]?.subLocationId}`;
    if (!selectedTechnicians[technicianKey]) {
      updatedTechnicianErrors[technicianKey] = "Please Select a Technician"; // Set error if technician is not selected
      hasError = true;
    } else {
      updatedTechnicianErrors[technicianKey] = ""; // Clear error if technician is selected
    }

    // Update error states
    setErrRemarks(updatedRemarksErrors);
    setErrDate(updatedDateErrors);
    setErrors(updatedErrors);
    setErrorsTechnician(updatedTechnicianErrors); // Update technician errors

    if (hasError) return;

    const postData = {
      acceptorName: `${getAccName.firstName} ${getAccName.lastName}`,
      acceptorID: getAccName.userId,
      acceptorMobileNumber: getAccName.mobileNumber,
      serverRecordId: data[index].serverRecordId,
      requestId: data[index].requestId,
      requesterName: data[index].requesterName,
      requesterMobileNumber: data[index].requesterMobileNumber,
      requesterType: data[index].requesterType,
      typeOfWork: data[index].typeOfWork,
      typeOfWorkId: data[index].typeOfWorkId,
      function: data[index].function,
      functionId: data[index].functionId,
      location: data[index].location,
      locationId: data[index].locationId,
      subLocation: data[index].subLocation,
      subLocationId: data[index].subLocationId,
      requiredDate: data[index].requiredDate,
      image: "",
      requesterRemarks: data[index].requesterRemarks,
      // accepterRemarks: remark,
      accepterRemarks: remarks,
      // corePermitRequired: data[index]?.corePermitRequired,
      corePermitRequired: corePermit,
      estimatedCompletionDate: date,
      technicianRemarks: data[index].technicianRemarks,
      technicianShiftId: technicianShiftId,
      technicianShiftName: technicianShiftName,
      status: "Approved",
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
    };

    try {
      setIsLoading(true);
      console.log("Sending postData to server:", postData);
      const headers = await GetApiHeaders();
      const response = await axios.post(newRqeuestAcceptReject, postData, { headers });

      if (response.data.statusCode == 200 || response.data.statusCode == 500) {
        setShowResponsePopup(true);
        setsubmitResponse(response.data.message);
        console.log("Request processed successfully:", response.data.message);
        fetchData();
      }
    } catch (error) {
      console.error("API error:", error.response ? error.response.data : error);
    } finally {
      setIsLoading(false);
    }
  };





  const closeBox = () => {
    setBoxVisible(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);

    //  window.location.reload();
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    // window.location.reload();
  };


  const handleDateChange = (e, index) => {
    const selectedDate = e.target.value;
    const updatedData = [...data];
    updatedData[index].estimatedCompletionDate = selectedDate; // Update date for the specific index
    setData(updatedData);
    // Clear date error for the specific index if a valid date is selected
    const updatedDateErrors = [...errDate];
    if (selectedDate) {
      updatedDateErrors[index] = ""; // Clear the error for this index
    }
    setErrDate(updatedDateErrors);
  };

  const handleRemark = (e, index) => {
    const updatedData = [...data];
    updatedData[index].accepterRemarks = e.target.value; // Update the specific item's remarks
    setData(updatedData);

    // Clear the error if remarks are valid
    if (e.target.value.trim() !== "") {
      const updatedErrors = [...errRemarks];
      updatedErrors[index] = ""; // Clear the error for the specific index
      setErrRemarks(updatedErrors);
    }
  };


  const fetchData = async () => {
    const getApi = newRqeuest;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const response = await axios.post(getApi, {}, { headers });
      console.log("res:", response);
      setData(response.data.response.newRequests);
      setIsLoading(false);
    } catch (err) {
      console.error("error", err);
    }
  };
  useEffect(() => {
    fetchData();
    getMasterData();

    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetAccName(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />

            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">New Requests</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2">
                                  New Requests
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>

                              {data.map((item, index) => {
                                var newDateFormat = moment(item.requesterSubmittedDate).format("DD-MM-YYYY hh:mm:ss A");
                                var parsedDate = moment(item.requiredDate, "DD-MM-YYYY");
                                var requiredDate = parsedDate.format("DD-MMM-YYYY");

                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    <Td>
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="acc mt-3 mb-2"
                                        id="dns"
                                      >
                                        <Accordion.Item eventKey="1">

                                          <Accordion.Header>
                                            <div
                                              key={index}
                                              style={{
                                                fontSize: 14,
                                                width: "100%",
                                              }}
                                              className="ProductName d-flex"
                                            >
                                              <span style={{ width: "16%" }}>
                                                <b>Request Id &nbsp;&nbsp;:&nbsp;</b>
                                                <span>{item.serverRecordId}</span>
                                              </span>
                                              <span style={{ width: "31%" }}>
                                                <b>Requested Date&Time &nbsp;&nbsp;:&nbsp;</b>
                                                <span>{newDateFormat}</span>
                                              </span>
                                              <span style={{ width: "32%" }}>
                                                <b>Requester Name &nbsp;&nbsp;:&nbsp;</b>
                                                <span>{item.requesterName}</span>
                                              </span>
                                              <span style={{ width: "20%" }}>
                                                <b>Status &nbsp;&nbsp;:&nbsp;</b>
                                                <span
                                                  style={{
                                                    color:
                                                      item.status === "Pending" ? "orange" :
                                                        item.requestStatusCode === 3 ? "#D50101" :
                                                          item.requestStatusCode === 0 ? "orange" :
                                                            "green",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {item.status === "Pending"
                                                    ? "Pending"
                                                    : item.requestStatusCode === 3
                                                      ? "Rejected"
                                                      : "Approved"}
                                                </span>
                                              </span>
                                            </div>

                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex">
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Requester Type
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.requesterType}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Function
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.function}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Type of work
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.typeOfWork}</span>
                                              </span>
                                            </div>

                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex mt-3">
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Location
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.location}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Sub Location
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.subLocation}</span>
                                              </span>
                                              <div style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                  Core Permit required ?
                                                </span>
                                                <span >  :&nbsp;
                                                </span>

                                                <div style={{ width: '60%', display: 'inline-block' }}>
                                                  <div>
                                                    <div className="form-check form-check-inline">
                                                      <input
                                                        className="form-check-input mt-3"
                                                        type="radio"
                                                        value="Yes" // Set the value as "Yes"
                                                        checked={data[index]?.corePermitRequired?.toLowerCase() === 'yes'} // Normalize to lowercase
                                                        onChange={() => handleRadioChange(index, 'Yes')}
                                                        disabled={item.status === "Approved"} // Disable input if status is "Approved"

                                                      />
                                                      <label className="form-check-label mt-2">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                      <input
                                                        className="form-check-input mt-3"
                                                        type="radio"
                                                        value="No" // Set the value as "No"
                                                        checked={data[index]?.corePermitRequired?.toLowerCase() === 'no'} // Normalize to lowercase
                                                        onChange={() => handleRadioChange(index, 'No')}
                                                        disabled={item.status === "Approved"} // Disable input if status is "Approved"

                                                      />
                                                      <label className="form-check-label mt-2">No</label>
                                                    </div>
                                                    {errors[index]?.corePermit && (
                                                      <span style={{ color: "red", marginLeft: "-14px" }}>
                                                        {errors[index].corePermit}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>

                                            </div>

                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex mt-3">
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Required Date
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.requiredDate}</span>
                                              </span>

                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Technician Role
                                                </span>
                                                <span>:&nbsp;</span>
                                                <div style={{ width: '50%', display: 'inline-block', position: 'relative' }}>
                                                  <select
                                                    style={{
                                                      width: '100%',
                                                      height: '32px',
                                                      borderRadius: '5px', // Add border-radius for the select box
                                                      padding: '4px', // Optional: Add padding for a better look
                                                      border: '1px solid #ccc', // Optional: Add border styling
                                                    }}
                                                    value={(() => {
                                                      // Check if data is already selected
                                                      if (data[index]?.technicianShiftName != undefined) {
                                                        return data[index]?.technicianShiftId;
                                                      } else {
                                                        // Get the current selected technician based on locationId and subLocationId
                                                        const currentValue = selectedTechnicians[`${item.locationId}-${item.subLocationId}`];
                                                        return currentValue ? currentValue.value : "";
                                                      }
                                                    })()}
                                                    onChange={(e) => {
                                                      const selectedOption = {
                                                        value: e.target.value,
                                                        label: e.target.options[e.target.selectedIndex].text,
                                                      };
                                                      handleTechnicianSelect(selectedOption, item.subLocationId, item.locationId);
                                                    }}
                                                    disabled={item.status === "Approved"} // Disable input if status is "Approved"
                                                  >
                                                    <option value="" disabled>Select Technician</option>
                                                    {technicianMastersData
                                                      .filter(
                                                        (val) => val.locationId === item.locationId && val.subLocationId === item.subLocationId
                                                      )
                                                      .map((technician) => (
                                                        <option key={technician.code} value={technician.code}>
                                                          {technician.name}
                                                        </option>
                                                      ))}
                                                  </select>
                                                  {errorsTechnician[`${item.locationId}-${item.subLocationId}`] && (
                                                    <span
                                                      style={{
                                                        color: "red",
                                                        position: "absolute",
                                                        top: "100%", // Place error below the select box
                                                        left: 0,
                                                        minHeight: "16px",
                                                        borderRadius: "4px", // Add border-radius for the error message
                                                        padding: "2px 4px", // Optional: Add padding for better appearance
                                                      }}
                                                    >
                                                      {errorsTechnician[`${item.locationId}-${item.subLocationId}`]}
                                                    </span>
                                                  )}
                                                </div>
                                              </span>


                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Estimated Date
                                                </span>
                                                <span>:&nbsp;</span>
                                                <div style={{ width: '60%', display: 'inline-block' }}>
                                                  <input
                                                    type="date"
                                                    min={new Date().toISOString().split("T")[0]}
                                                    style={{
                                                      width: 108,
                                                      height: 32,
                                                      borderRadius: '5px', 
                                                      padding: '4px', 
                                                      border: '1px solid #ccc', 
                                                    }}
                                                    onChange={(e) => handleDateChange(e, index)} // Pass index to handleDateChange
                                                    value={
                                                      item.status === "Approved"
                                                        ? estDate || moment(item.estimatedCompletionDate).format("YYYY-MM-DD")
                                                        : data[index].estDate
                                                    }
                                                    disabled={item.status === "Approved"} // Disable input if status is "Approved"
                                                  />
                                                </div>

                                                <div>
                                                  <span style={{ color: "red" }}>{errDate[index]}</span> {/* Show date error for the specific index */}
                                                </div>
                                              </span>
                                            </div>

                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex mt-3">
                                              {item.requesterRemarks && (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                    Requester Remarks
                                                  </span>
                                                  <span>:&nbsp;</span>
                                                  <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>
                                                    {item.requesterRemarks}
                                                  </span>
                                                </span>
                                              )}
                                              <span style={{ width: '33.4%' }}>
                                                <div style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                  Remarks
                                                </div>
                                                <span >  :&nbsp;
                                                </span>
                                                <div style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>
                                                  <textarea
                                                    type="text"
                                                    style={{ borderColor: "silver", borderRadius: 5 }}
                                                    value={item.accepterRemarks} 
                                                    onChange={(e) => handleRemark(e, index)} 
                                                    disabled={item.status === "Approved"} // Disable input if status is "Approved"
                                                  />
                                                </div>
                                                <div>
                                                  <span style={{ color: "red", marginLeft: "166px" }}>
                                                    {errRemarks[index]} {/* Display error for the specific index */}
                                                  </span>
                                                </div>
                                              </span>
                                            </div>

                                            <div className="mt-0 d-flex justify-content-end">

                                              <span className="mt-3">
                                                {item.status === "Pending" && (
                                                  <>
                                                    <button onClick={() => handleAprove(index)} className="btns">
                                                      Approve
                                                    </button>
                                                    <button
                                                      className="btnsReject ml-2 mb-2"
                                                      onClick={() => handleReject(index)}
                                                    >
                                                      Reject
                                                    </button>
                                                  </>
                                                )}
                                              </span>
                                            </div>
                                          </Accordion.Body>

                                        </Accordion.Item>
                                      </Accordion>
                                    </Td>
                                  </Tr>
                                );
                              })}


                            </Tbody>
                          </Table>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">New Request</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleClose}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">


                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading && (
                <Loader_Bulk
                  loading={isLoading}
                  message={"Fetching Data. Please Wait..!"}
                  loderimage={loaderImage}
                />
              )}
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body >
  );
};

export default NewRequest;
