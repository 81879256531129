import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import { strings } from "../Utilities/strings";
import Loader from "../Utilities/Loader";
import * as XLSX from "xlsx";
import moment from "moment";
import Delete from "../images/ic_delete.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  rmActivity,
  RmhistoryGet,
  deleteActivity,
  FarmOperation_getMasters,
} from "../Utilities/URLCONSTANTS";
//  import { useSession } from 'react-client-session';
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import closedProducts from "../images/closed-products.png";

import user from "../images/user.png";
import UploadFile from "../images/uploadfile.png";

import productrequestImage from "../images/product_request.png";

import issuedProducts from "../images/issued-products.png";

import PendingProducts from "../images/Pending_products.png";
//ProductMaster
const FarmOperationsDripSystem = ({ }) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/fo_loader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [id, setId] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationDropDownData, setlocationDropDownData] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [date, setDate] = useState("");
  const [dripID, setDripID] = useState("");
  const [spares, setSpares] = useState("");
  const [fuel, setfuel] = useState("");
  const [cost, setCost] = useState("");
  const [remarks, setRemarks] = useState("");
  const [errorService, setErrorService] = useState("");
  const [errorlocation, setErrorlocation] = useState("");
  const [errorcost, setErrorCost] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [showPopup, setShowPopup] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [getActivityType, setGetActivityType] = useState([]);
  const [locationDropDownDataOriginal, setlocationDropDownDataOriginal] =
    useState([]);
  const [dripIdOriginal, setdripIdOriginal] = useState([]);
  const [errorDripId, setErrorDripId] = useState("");
  const [getDripId, setGetDripId] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    activityType: "",
    requiredDate: "",
    locationId: "",
    locationName: "",
    service: "",
    dripID: "",
    repair: "",
    cost: "",
    remarks: "",
    submittedDate: "",
  });
  const [data, setData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState("");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [dripIdName, setDripIdName] = useState();
  const [profilePicUrlShow, setProfilePicUrlShow] = useState([]);
  const [profilePicUrl, setProfilePicUrl] = useState([]);

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter((item) => {
        return (
          item.requiredDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.service.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.dripSystemId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.spares.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.cost
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.remarks.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(filteredResults);
      handlePageClick(1);
    }
  };
  console.log("filterdData", data);
  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
    fetchLocationDropdownData();
  }, [data]);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleCancel_new = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Required  Date",
        "Drip System name",
        "Location Name",
        "Service",
        "Spares",
        "Cost",
        "Remarks",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.requiredDate,
        item.dripSystemName,
        item.locationName,
        item.service,
        item.spares,
        item.cost,
        item.remarks,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DripSystems");

    // Save the file
    XLSX.writeFile(wb, "DripSystems.xlsx");
  };

  const handleCancel = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(false);
  };
  const handleClose = () => {
    //yes
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  const fetchLocationDropdownData = async () => {
    const apiUrl = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("responseLocation", response);
        setlocationDropDownDataOriginal(response.data.response.subSiteMaster);
        setdripIdOriginal(response.data.response.dripSystemMaster);
        setGetActivityType(response.data.response.rmActivitiesMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  console.log("locationddd=", locationDropDownDataOriginal);

  const fetchData = async () => {
    const apiUrl = RmhistoryGet;
    const headers = await GetApiHeaders();

    const getDataObject = {
      mobileNumber: headers.mobileNumber,
      userId: headers.userId,
      activityType: "Drip Systems",
      startDate: "",
      endDate: "",
    };

    setIsLoading(true);
    axios
      .post(apiUrl, getDataObject, { headers })
      .then((response) => {
        console.log(
          "main table response ::;;",
          JSON.stringify(response.data.response.rmHistory)
        );
        setData(response.data.response.rmHistory);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
  }, [modalData]);
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);

    setShowWarningPopup(false);
    window.location.reload();
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleModalOpen = () => {
    if (locationDropDownDataOriginal.length > 0) {
      const uniqueLocationNames = new Set();
      const uniqueDripSystemMaster = locationDropDownDataOriginal.filter(
        (item) => {
          if (!uniqueLocationNames.has(item.locationId)) {
            uniqueLocationNames.add(item.locationId);
            return true;
          }
          return false;
        }
      );
      setlocationDropDownData(uniqueDripSystemMaster);
    }
    console.log();
    if (dripIdOriginal.length > 0) {
      const uniqueDripId = new Set();
      const uniqueDripSystemMaster = dripIdOriginal.filter((item) => {
        if (!uniqueDripId.has(item.name)) {
          uniqueDripId.add(item.name);
          return true;
        }
        return false;
      });
      setGetDripId(uniqueDripSystemMaster);
    }

    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId(0);
    setSelectedLocation("");
    setServiceId("");
    setDripID("");
    setSpares("");
    setfuel("");
    setCost("");
    setRemarks("");
    setDate("");
    setModalData({
      id: "",
      activityType: "",
      requiredDate: "",
      locationId: "",
      locationName: "",
      service: "",
      dripID: "",
      repair: "",
      cost: "",
      remarks: "",
      submittedDate: "",
      serverRecordId: "",
    });
  };
  const handledateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    if (selectedDate) {
      setErrorDate("");
    }
  };

  const handlelocationChange = (e) => {
    setSelectedLocation(e.target.value);
    const LocationId =
      e.target.options[e.target.selectedIndex].getAttribute("data-locationID");
    setSelectedLocationId(LocationId);
    if (!e.target.value) {
      setErrorlocation("Please Select Location");
    } else {
      setErrorlocation("");
    }
  };

  const handleServiceChange = (e) => {
    const input = e.target.value;
    setServiceId(input);
    if (input) {
      setErrorService("");
    }
  };
  const handleDripChange = (e) => {
    const input = e.target.value;
    //const result = input.replace(/[^a-z_\s]/gi, '');
    setDripID(input);
    const dripIdChange =
      e.target.options[e.target.selectedIndex].getAttribute(" data-dripId");
    setDripIdName(dripIdChange);
    if (input) {
      setErrorDripId("");
    }
  };
  const handleSparesChange = (e) => {
    const inputSpares = e.target.value;
    setSpares(inputSpares);
  };

  const handleCostChange = (e) => {
    const inputunitCost = e.target.value;
    if (!isNaN(inputunitCost)) {
      setCost(inputunitCost);
    }
    if (inputunitCost) {
      setErrorCost("");
    }
  };

  const handleSubmit = async (modalData) => {
    const apiUrl = rmActivity;
    const headers = await GetApiHeaders();

    // const dataToSend = {
    //   serverRecordId: { buttonText } == "Submit" ? 0 : parseInt(id),
    //   activityType: getActivityType[0].name,
    //   requiredDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss"),
    //   locationName: selectedLocation,
    //   service: serviceId,
    //   dripSystemId: parseInt(dripID),
    //   spares: spares,
    //   cost: parseInt(cost),
    //   remarks: remarks,
    //   imageList: [{ image: profilePicUrl.toString() }],
    //   fuel: fuel,
    //   submittedDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss"),
    // };


    const dataToSend = {
      serverRecordId: buttonText === "Submit" ? 0 : parseInt(id),
      activityType: getActivityType[0].name,
      requiredDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss"),
      locationName: selectedLocation,
      service: serviceId,
      dripSystemId: parseInt(dripID),
      spares: spares,
      cost: parseInt(cost),
      remarks: remarks,
      imageList:
        profilePicUrl && profilePicUrl.toString().length > 0
          ? profilePicUrl
            .toString() // Convert to string (handles cases where it's an array or other type)
            .split(",")
            .map((img) => img.trim())
            .filter((img) => img.length > 0) // Remove empty values
            .map((img) => ({ image: img })) // Convert to object format
          : [],
      fuel: fuel,
      submittedDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss"),
    };

    console.log(dataToSend);

    console.log("request", dataToSend);
    try {
      //  if (selectedLocation !='' && serviceId !='' && dripID !='' && date !='' && spares !='' && fuel !='' && cost !='') {
      if (cost != "" && dripID != "" && date != "" && selectedLocation != "") {
        setIsLoading(true);
        const response = await axios.post(apiUrl, dataToSend, { headers });
        console.log("response submit:: ", response);
        setsubmitResponse(response.data.message);
        setShowModal(false);
        setShowResponsePopup(true);

        setIsSubmitted(true);
        setIsLoading(false);
      } else {
        // alert("fail")

        setErrorDripId(dripID === "" ? "Please Enter a DripId" : "");
        setErrorlocation(
          selectedLocation === "" ? "Please Select Location" : ""
        );
        setErrorDate(date === "" ? "Please Enter a Date" : "");

        setErrorCost(cost === "" ? "Please Enter a Cost" : "");
        // setIsSubmitted(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitted(false);
    }
  };

  const updatedItems = (item) => {
    setModalData(item);
    setId(item.serverRecordId);
    setDate(
      item.submittedDate !== "" && item.submittedDate !== null
        ? item.submittedDate.toString().split(" ")[0]
        : ""
    );
    setSelectedLocation(item.locationName);
    setServiceId(item.service);
    if (locationDropDownDataOriginal.length > 0) {
      const uniqueLocationNames = new Set();
      const uniqueDripSystemMaster = locationDropDownDataOriginal.filter(
        (item) => {
          if (!uniqueLocationNames.has(item.locationId)) {
            uniqueLocationNames.add(item.locationId);
            return true;
          }
          return false;
        }
      );
      setlocationDropDownData(uniqueDripSystemMaster);
    }
    if (dripIdOriginal.length > 0) {
      const uniqueDripId = new Set();
      const uniqueDripSystemMaster = dripIdOriginal.filter((item) => {
        if (!uniqueDripId.has(item.name)) {
          uniqueDripId.add(item.name);
          return true;
        }
        return false;
      });
      setGetDripId(uniqueDripSystemMaster);
    }
    setProfilePicUrlShow(item.image);
    setProfilePicUrl(item.image);

    setDripID(item.dripSystemId);
    setSpares(item.spares);
    setCost(item.cost);
    setRemarks(item.remarks);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };
  const deleteItem = async (item) => {
    // alert(JSON.stringify(item));
    const apiUrl = deleteActivity;
    const headers = await GetApiHeaders();

    const dataToSend = {
      id: item.serverRecordId,
    };
    setIsLoading(true);
    const response = await axios.post(apiUrl, dataToSend, { headers });
    console.log("response submit:: ", response);
    setsubmitResponse(response.data.message);
    setShowResponsePopup(true);
    setIsLoading(false);
  };
  const handleClear = () => {
    setSearchTerm("");
  };
  const setMinDateToToday = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const uniqueLocations = [
    ...new Set(locationDropDownData.map((item) => item.locationName)),
  ];
  const handleFileChange = (event) => {
    const selectedImages = event.target.files;

    if (selectedImages && selectedImages.length > 0) {
      const updatedProfilePicUrlShow = [];
      const updatedProfilePicUrl = [];

      Array.from(selectedImages).forEach((image, index) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataURL = reader.result;
          updatedProfilePicUrlShow[index] = dataURL;

          // Remove the data URL prefix
          const base64 = dataURL.split(",")[1];
          updatedProfilePicUrl[index] = base64;

          setProfilePicUrlShow([...updatedProfilePicUrlShow]);
          setProfilePicUrl([...updatedProfilePicUrl]);
        };

        reader.onerror = () => {
          // Handle error reading the selected image
        };

        reader.readAsDataURL(image);
      });
    } else {
      // No new images selected, retain existing image URLs
      // You might want to adjust this based on your specific use case
      setProfilePicUrlShow([...profilePicUrlShow]);
      setProfilePicUrl([...profilePicUrl]);
    }
  };
  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (aValue && bValue) {
        // If the value is a date, convert it to a Date object
        if (aValue instanceof Date && bValue instanceof Date) {
          aValue = aValue.getTime(); // Convert to timestamp for comparison
          bValue = bValue.getTime(); // Convert to timestamp for comparison
        } else {
          // If it's a string, convert to lowercase for case-insensitive comparison
          aValue = aValue?.toString().toLowerCase();
          bValue = bValue?.toString().toLowerCase();
        }

        // Compare the values
        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      }
    }
    return 0;
  });


  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <h5 className="Roles_text">Farm Operations</h5>
                <div>
                  <h5 className="mb-0 Sub_role_text">Drip Systems </h5>
                </div>
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5 className="mb-0 Sub_role_text"></h5>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add Drip System"
                      >
                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline "></div>
                    <div>
                      <div
                        className="card flex-grow-1 "
                        style={{
                          border: "1px solid #0000001A",
                          boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div className="card-body " style={{ padding: "20px" }}>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ paddingBottom: 20 }}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                {/* Add more options as needed */}
                              </select>
                              &nbsp;&nbsp;
                              <label> Entries</label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div style={{ position: "relative" }}>
                                <input
                                  type="text"
                                  placeholder="🔍 Type to Search..."
                                  value={searchTerm}
                                  onChange={(e) => handleFilter(e.target.value)}
                                  style={{
                                    height: "36px",
                                    width: "160px",
                                    paddingRight: "30px",
                                  }}
                                  className="mr-2"
                                />
                                {searchTerm && (
                                  <button
                                    className="btn btn-link"
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "10px",
                                      transform: "translateY(-50%)",
                                      zIndex: 1,
                                    }}
                                    onClick={() => handleFilter("")}
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                )}
                              </div>
                              &nbsp;
                              {/* Add the Excel export button */}
                              <button
                                className="btn btn-secondary buttons-excel buttons-html5"
                                onClick={exportToExcel}
                              >
                                Download Excel
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <div style={{ overflow: "auto" }}>
                              <Table className="table table-bordered  table-striped">
                                {" "}
                                <Thead>
                                  <Tr>
                                    <Th className="text-center">S.No</Th>
                                    {/* <Th className="text-center">Date</Th> */}
                                    <Th className="text-center" onClick={() => handleSort("requiredDate")}>
                                      Date{" "}
                                      <i
                                        className={`fa ${sortConfig.key === "requiredDate"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity`}
                                      ></i>
                                    </Th>
                                    {/* <Th className="text-center">
                                      Drip System ID
                                    </Th> */}

                                    <Th className="text-center" onClick={() => handleSort("dripSystemName")}>
                                      Drip System ID{" "}
                                      <i
                                        className={`fa ${sortConfig.key === "dripSystemName"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity`}
                                      ></i>
                                    </Th>
                                    {/* <Th className="text-center">Location</Th> */}
                                    <Th className="text-center" onClick={() => handleSort("locationName")}>
                                      Location{" "}
                                      <i
                                        className={`fa ${sortConfig.key === "locationName"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity`}
                                      ></i>
                                    </Th>
                                    {/* <Th className="text-center">Service</Th> */}
                                    <Th className="text-center" onClick={() => handleSort("service")}>
                                      Service{" "}
                                      <i
                                        className={`fa ${sortConfig.key === "service"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity`}
                                      ></i>
                                    </Th>
                                    {/* <Th className="text-center">Spares</Th> */}
                                    <Th className="text-center" onClick={() => handleSort("spares")}>
                                      Spares{" "}
                                      <i
                                        className={`fa ${sortConfig.key === "spares"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity`}
                                      ></i>
                                    </Th>
                                    {/* <Th className="text-center">Cost</Th> */}
                                    <Th className="text-center" onClick={() => handleSort("cost")}>
                                      Cost{" "}
                                      <i
                                        className={`fa ${sortConfig.key === "cost"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity`}
                                      ></i>
                                    </Th>
                                    {/* <Th className="text-center">Remarks</Th> */}
                                    <Th className="text-center" onClick={() => handleSort("remarks")}>
                                      Remarks{" "}
                                      <i
                                        className={`fa ${sortConfig.key === "remarks"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity`}
                                      ></i>
                                    </Th>
                                    <Th className="text-center">Images</Th>
                                    <Th className="text-center">Actions</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {/* {data.map((item) => ( */}

                                  {sortedItems.length === 0 ? ( // Check if no data is found
                                    <Tr>
                                      <Td
                                        colSpan={9}
                                        className="text-center"
                                        style={{ padding: 195 }}
                                      >
                                        Data is not available
                                      </Td>
                                    </Tr>
                                  ) : (
                                    sortedItems.map((item, index) => {
                                      var requiredDate = moment(item.requiredDate).format("DD-MMM-YYYY   ");
                                      return (
                                        <Tr key={index}>
                                          <Td className="text-left">
                                            {" "}
                                            {(currentPage - 1) * itemsPerPage +
                                              index +
                                              1}
                                          </Td>
                                          <Td className="text-left">
                                            {requiredDate}
                                          </Td>
                                          <Td className="text-left">
                                            {item.dripSystemName}
                                          </Td>
                                          <Td className="text-left">
                                            {item.locationName}
                                          </Td>
                                          <Td className="text-left">
                                            {item.service}
                                          </Td>
                                          <Td className="text-left">
                                            {item.spares}
                                          </Td>
                                          <Td className="text-right">
                                            {item.cost}
                                          </Td>
                                          <Td className="text-right">
                                            {item.remarks}
                                          </Td>
                                          <Td>
                                            {item.image.map((image, index) => (
                                              <img
                                                key={index}
                                                src={image}
                                                style={{
                                                  width: "30px",
                                                  height: "40px",
                                                  cursor: "pointer",
                                                  border: "1px solid black",
                                                  marginRight: "5px",
                                                }}
                                                onClick={() => handleImageClick(image)}
                                              />
                                            ))}
                                          </Td>
                                          <Td className="text-center">
                                            <div>
                                              <FontAwesomeIcon
                                                icon={faEdit}
                                                className="plus_icon mr-1 text-primary"
                                                data-placement="top"
                                                title="Edit User Master"
                                                onClick={() =>
                                                  updatedItems(item)
                                                }
                                              />
                                              <img
                                                src={Delete}
                                                alt="downloadImg"
                                                style={{
                                                  height: "15px",
                                                  marginTop: -5,
                                                  marginLeft: 3,
                                                }}
                                                onClick={() => deleteItem(item)}
                                              />
                                            </div>
                                          </Td>
                                        </Tr>
                                      );
                                    })
                                  )}
                                </Tbody>
                                {isLoading && (
                                  <Loader
                                    loading={isLoading}
                                    message={"Fetching Data. Please Wait..!"}
                                    loderimage={loaderImage}
                                  />
                                )}
                              </Table>
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-between mb-10"
                            style={{ borderRadius: 5 }}
                          >
                            <div>
                              Showing{" "}
                              {Math.min(
                                (currentPage - 1) * itemsPerPage + 1,
                                totalItems
                              )}
                              &nbsp;to{" "}
                              {Math.min(
                                (currentPage - 1) * itemsPerPage +
                                currentItems.length,
                                totalItems
                              )}
                              &nbsp; of {totalItems} entries
                            </div>
                            <div>
                              <div className="div-button text-center">
                                <button
                                  className="borderleft-radius-5"
                                  // className='ul-pagination'
                                  onClick={() =>
                                    handlePageClick(currentPage - 1)
                                  }
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>

                                <button
                                  className="ul-pagination page-item"
                                  style={{
                                    backgroundColor:
                                      currentPage == 1 ? "#0056b3" : "white",
                                    color: currentPage == 1 ? "white" : "black",
                                  }}
                                  key={1}
                                  onClick={() => handlePageClick(1)}
                                  disabled={currentPage === 1}
                                >
                                  {1}
                                </button>
                                {pageNumbers.map((number) => (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage == number
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage == number
                                          ? "white"
                                          : "black",
                                    }}
                                    key={number}
                                    onClick={() => handlePageClick(number)}
                                    disabled={currentPage === number}
                                  >
                                    {number}
                                  </button>
                                ))}
                                {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                  currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) - 1 &&
                                  currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) && (
                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      ...
                                    </button>
                                  )}
                                {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage ==
                                          Math.ceil(totalItems / itemsPerPage)
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage ==
                                          Math.ceil(totalItems / itemsPerPage)
                                          ? "white"
                                          : "black",
                                    }}
                                    key={Math.ceil(totalItems / itemsPerPage)}
                                    onClick={() =>
                                      handlePageClick(
                                        Math.ceil(totalItems / itemsPerPage)
                                      )
                                    }
                                    disabled={
                                      currentPage ===
                                      Math.ceil(totalItems / itemsPerPage)
                                    }
                                  >
                                    {Math.ceil(totalItems / itemsPerPage)}
                                  </button>
                                )}
                                <button
                                  className="borderright-radius-5"
                                  onClick={() =>
                                    handlePageClick(currentPage + 1)
                                  }
                                  disabled={indexOfLastItem >= totalItems}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger  close_btn"
                                onClick={handleCancel_new}
                              >
                                {" "}
                                {/*closeWarning */}X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">
                                  Drip Systems
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Date <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      placeholder="Select Date"
                                      value={date}
                                      onChange={handledateChange}
                                      min={setMinDateToToday()} // Set the minimum date to today
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorDate}{" "}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-sm font-weight-600 mt-2"
                                  >
                                    Location{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <select
                                    id="dropdown"
                                    type="dropdown"
                                    className="form-select p-2"
                                    value={selectedLocation}
                                    onChange={handlelocationChange}
                                  >
                                    <option value="">
                                      {selectedLocation === ""
                                        ? "Select Location"
                                        : selectedLocation}
                                    </option>
                                    {locationDropDownDataOriginal.map((item) => (
                                      <option
                                        key={item.code}
                                        value={item.name}
                                        data-locationID={item.code}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorlocation}{" "}
                                  </span>
                                  {/* <span style={{ color: 'red', display: errorlocation ? 'block' : 'none' }}>{errorlocation}</span> */}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-sm font-weight-600 mt-2"
                                  >
                                    Drip System ID{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <select
                                    id="dropdown"
                                    type="dropdown"
                                    className="form-select p-2"
                                    value={dripID}
                                    onChange={handleDripChange}
                                  >
                                    <option value="">
                                      Select Drip System ID
                                    </option>
                                    {getDripId.map((item) => (
                                      <option
                                        key={item.code}
                                        value={item.code}
                                        data-dripId={item.name}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorDripId}{" "}
                                  </span>
                                </div>
                                {/* <div className='col-md-6 col-12 mt-2'>
                                  <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1"
                                  >Drip System ID <span style={{ color: "red" }}>*</span> </label>
                                 <div className="input-group input-group w-100">
                                    <input type="number" className="form-control p-2 " placeholder='drip ID' value={dripID} onChange={handleDripChange} />
                                  </div>
                                  <span style={{ color: "red" }}> {errorDripId} </span>
                                </div> */}
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Service{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Service"
                                      value={serviceId}
                                      onChange={handleServiceChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Spares{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Spares"
                                      value={spares}
                                      onChange={handleSparesChange}
                                    />
                                  </div>
                                  {/* <span style={{ color: "red" }}> {Errorspares} </span> */}
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Cost <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2"
                                      placeholder="Cost"
                                      value={cost}
                                      onChange={handleCostChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorcost}{" "}
                                  </span>
                                </div>
                                {/* <div className='col-md-6 col-12 mt-2'>
                                  <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1"
                                  >Fuel (In Liters) <span style={{ color: "red" }}>*</span> </label>
                                  <div className="input-group input-group w-100">
                                    <input type="text" className="form-control p-2 " placeholder='fuel' value={fuel} onChange={handlefuelChange} />
                                  </div>
                                </div>     */}
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Remarks{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Remarks"
                                      value={remarks}
                                      inputMode="text"
                                      onChange={(e) =>
                                        setRemarks(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label>
                                    Upload Image
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      multiple
                                    /> <img
                                      src={UploadFile}
                                      style={{ height: 70 }}
                                      alt="Upload Preview"
                                    /> </label>
                                  {profilePicUrlShow.map((url, index) => (
                                    <div key={index}>
                                      <img
                                        src={url}
                                        alt={`Selected ${index + 1}`}
                                        style={{
                                          maxHeight: "80px",
                                          maxWidth: "50%",
                                          border: "1px solid black",
                                        }}
                                      />
                                    </div>
                                  ))}

                                </div>
                              </div>{" "}
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => {
                                    setModalData({
                                      id: id,
                                      requiredDate: date,
                                      locationId: selectedLocation.code,
                                      locationName:
                                        selectedLocation.locationName,
                                      service: serviceId,
                                      dripID: dripID,
                                      spares: spares,
                                      // fuel:fuel,
                                      cost: cost,
                                      remark: remarks,
                                      submittedDate: data,
                                      serverRecordId: "",
                                    });
                                    handleSubmit(modalData);
                                  }}
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleCancel_new}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Drip Systems
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Drip Systems{" "}
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default FarmOperationsDripSystem;
