
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SideMenu from '../../Pages/SideMenu';
import Header from '../../Pages/Header';
import Footer from '../../Pages/Footer';
import Logout from '../../Pages/Logout';
import { strings } from '../../Utilities/strings'
import Loader from '../../Utilities/Loader';
import * as XLSX from 'xlsx';
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//for modal
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from '../../Login';
import { getMasters, FarmOperation_getMasters, FarmOperationAcceptHistory,sendCentralGridDataToMail, RmhistoryGet, reportForStockInwardRequestReturn } from '../../Utilities/URLCONSTANTS';
import { GetApiHeaders } from '../../Utilities/NetworkUtilities';
import { filterObjects } from '../../Utils';
import closedProducts from '../../images/closed-products.png';
import user from '../../images/user.png';
import productrequestImage from '../../images/product_request.png'
import issuedProducts from '../../images/issued-products.png';
import PendingProducts from '../../images/Pending_products.png';
import { date } from 'yup';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../../Utilities/utils';

//ProductMaster

const OpenStockReport = ({ }) => {
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [loaderImage, setLoaderImage] = useState(require('../../images/cs_loader.gif'))
    const [submitResponse, setsubmitResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showResponsePopup, setShowResponsePopup] = useState(false);
    const [id, setId] = useState('');
    const [selectedLocation, setSelectedLocation] = useState({ code: "", name: "" });
    const [locationDropDownData, setlocationDropDownData] = useState([]);
    const [typeOfOperation, setTypeOfOperation] = useState([])
    const [operations, setOpeartions] = useState('');

    const [serviceId, setServiceId] = useState("");
    const [tractorsID, setTractorsID] = useState("");
    const [tractorType, setTractorType] = useState("")
    const [buttonText, setButtonText] = useState("Submit");
    const [ActivityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [todayDate, settodayDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);

    const [popupText, setPopupText] = useState('Do you want to close Spray Service');
    const [modalData, setModalData] = useState({
        serverRecordId: '',
        activityType: '',
        requiredDate: '',
        locationId: '',
        tractorType: '',
        locationName: '',
        registrationNo: '',
        service: '',
        tractorsID: '',
        repair: '',
        cost: '',
        startTime: '',
        endTime: '',
        remarks: '',
        submittedDate: ''
    });

    const [data, setData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState('');
    const [showWarningPopup, setShowWarningPopup] = useState(false);

    const [filteredData, setFilteredData] = useState([]); // For filtered results
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItems, setTotalItems] = useState();
    const [productCategoryOptions, setProductCategoryOptions] = useState([]);
    const [productNameOptionsOriginal, setProductNameOptionsOriginal] = useState([]);
    const [storeLocationOptions, setStoreLocationOptions] = useState([]);
    const [productCategory, setProductCategory] = useState("");
    const [productCategoryID, setProductCategoryID] = useState("");
    const [productNameOptions, setProductNameOptions] = useState([]);
    const [errorProductCategory, setErrorProductCategory] = useState("");
    const [productName, setProductName] = useState("");
    const [productNameID, setProductNameID] = useState("");
    const [wmy, setwmy] = useState("w");
    const [location, setLocation] = useState('');
    const [locationID, setLocationID] = useState('');
    const [WeekSelected, setWeekSelected] = useState('yes');
    const [monthSelected, setmonthSelected] = useState('no');
    const [yearSelected, setYearSelected] = useState('no');
    const [allSelected, setAllSelected] = useState('no');

    // const totalItems = filteredData.length;
    // In your handleFilter function, update filteredData based on searchTerm
    const handleFilter = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (data != null && data != undefined) {
            const filteredResults = data.filter((item) =>
                item.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.product.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredData(filteredResults);
        }
    };




    useEffect(() => {
        if (data != null && data != undefined) {
            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();
            var maxDate = year + '-' + month + '-' + day;
            setFilteredData(data);
            setTotalItems(data.length);

        }
    }, [data]);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1); // Reset to the first page when changing items per page.
    };

    // const totalItems = data.length;

    const pageNumbers = [];
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handlePrint = () => {
        window.print();
    };

    const exportToExcel = () => {
        const data = [
            ['S.no', 'Date', 'Location', 'Category', 'Product', 'Available Qty', 'Issue Qty', 'Balance Qty', 'Expired Qty'], // Custom headers
            // ...currentItems.map((item) => [
            ...filteredData.map((item, index) => [
                index + 1,
                item.date,
                item.location,
                item.category,
                item.product,
                item.availableQuantity,
                item.issueQuantity,
                item.balanceQuantity,
                item.expiredQuantity
            ]),
        ];
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        // Save the file
        XLSX.writeFile(wb, 'OpenStockReport.xlsx');
    };


    const handleTypeOfOeration = (e) => {
        const selectTractorType = e.target.value
        setOpeartions(selectTractorType)
    }

    const handleWMYChange = (e) => {
        $('.date_show_hide').hide();
        let chk_val = (e.target.value);
        setwmy(chk_val);

        if (chk_val == 'W') {
            setWeekSelected('yes');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('no');
        }
        else if (chk_val == 'M') {
            setWeekSelected('no');
            setmonthSelected('yes');
            setYearSelected('no');
            setAllSelected('no');
        }
        else if (chk_val == 'Y') {
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('yes');
            setAllSelected('no');
        }
        else if (chk_val == 'All') {
            setwmy("");
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('yes');
            $('.date_show_hide').show();
        }
    }

    //for data table 


    function extractTimeFromDate(dateTimeString) {
        const dateObject = new Date(dateTimeString);
        const timeOnly = dateObject.toLocaleTimeString('en-US', { hour12: true });
        return timeOnly;
    }



    const tableRef = useRef(null);
    useEffect(() => {
        // Check if DataTable is already initialized
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            // Destroy the existing DataTable instance
            $(tableRef.current).DataTable().destroy();
        }

        // Initialize the DataTable
        $(tableRef.current).DataTable({
            ordering: false, // Disable sorting
        });
        // fetchData();
        fetchDataReports();
        getmasters();
    }, []);

    const getmasters = async () => {
        const apiUrl = getMasters; // Replace with your actual API endpoint
        const headers = await GetApiHeaders();
 
        setIsLoading(true);
        axios.post(apiUrl, null, { headers })
            .then((response) => {
                setIsLoading(false);
                setProductCategoryOptions(response.data.response.productCategoryMaster);
                setProductNameOptionsOriginal(response.data.response.productsMaster);
                setStoreLocationOptions(response.data.response.storeLocation);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };

    const handleProductCategory = async (event) => {
        const selectedCategory = event.target.value;
        const selectedCategoryId = event.target.options[event.target.selectedIndex].getAttribute('code');
        console.log("Category", selectedCategory + "--" + selectedCategoryId)
        setProductCategory(selectedCategory);
        setProductCategoryID(selectedCategoryId);
        var productNameOptionsList = await filterObjects(productNameOptionsOriginal, "productCategoryId", selectedCategoryId) // kiran
        console.log("productNameOptionsList", productNameOptionsList)
        if (productNameOptionsList.length > 0) {
            setProductNameOptions(productNameOptionsList)
        }
        console.log("=========>", productNameOptionsList)
        if (selectedCategory) {
            setErrorProductCategory('');
        }
    }

    const fetchTypeOfOperationDropdownData = async () => {
        const apiUrl = FarmOperation_getMasters;
        const headers = await GetApiHeaders();
        axios.get(apiUrl, { headers })
            .then((response) => {
                console.log("responseLocation", response);
                setTypeOfOperation(response.data.response.subSiteMaster)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    ////Data table api IntigrationN 

    const fetchData = async () => {
        // debugger;
        const apiUrl = FarmOperationAcceptHistory;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);
        setIsLoading(true);
        axios.get(apiUrl, { headers }).then((response) => {
            // debugger;
            setIsLoading(false);
            setData(response.data.response.rmHistory);
            const respData = response.data.response.rmHistory;
            const activityType = [...new Set(respData.map(item => item.activityType))];
            setActivityData(activityType);
            setLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        });
        // }, 900);
    };

    useEffect(() => {
        fetchData();
    }, []);


    const fetchDataReports = async () => {

        const fetchApi = reportForStockInwardRequestReturn;
        const headers = await GetApiHeaders();
        const reqObj = {
            "WMY": "w",
            "product": "",
            "category": "",
            "location": "",
            "startDate": "",
            "endDate": "",
            "reportType": "openStock"
        }
        try {
            const response = await axios.post(fetchApi, reqObj, { headers })
            console.log("update Activity", response.data.response.openStock)
            setData(response.data.response.openStock)
        } catch (err) {
            console.log("err123", err)
        }
    }

    const handleSubmit = async (event) => {
        // debugger;
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "product": productNameID,
            "category": productCategoryID,
            "location": location,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "reportType": "openStock"
        }
        const fetchApi = reportForStockInwardRequestReturn;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);
        setIsLoading(true);
        axios.post(fetchApi, reqObj, { headers }).then((response) => {
            if (response.data.response == "undefined" || response.data.response == "") {
                alert('ent');
                setData([]);
            }
            else
                setData(response.data.response.openStock);
            console.log("submitted data is" + response.data.response.openStock)
            setIsLoading(false);
        })
    }

    const handleChange = (event) => {
        // debugger
        const { name, value } = event.target;
        if (name === 'machineSelecetd') {
            setLocation(value);
            setLocationID(event.target.options[event.target.selectedIndex].getAttribute('code'))
        }
        else if (name === 'product') {
            // const selectedCategory = event.target.value;
            setProductNameID(event.target.options[event.target.selectedIndex].getAttribute('code'));
            setProductName(value);
        }
    }

    const Sendmail = async (event) => {
        // debugger;
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "product": productNameID,
            "category": productCategoryID,
            "location": location,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "reportType": "openStock"
        }
        const fetchApi = sendCentralGridDataToMail;
        const headers = await GetApiHeaders();
        //console.log("Dynamic Headers::", headers);
        setIsLoading(true);
        axios.post(fetchApi, reqObj, { headers }).then((response) => {
            //setData(response.data.response.rmHistory);
            setIsLoading(false);
        })
    }

    function resetData() {
        setwmy("w");
        setLocation('');
        setLocationID('');
        setProductCategory("");
        setProductCategoryID("");
        setProductName("");
        setProductNameID("");
        setStartDate(new Date());
        setEndDate(new Date());
        settodayDate(new Date());
        setWeekSelected('yes');
        setmonthSelected('no');
        setYearSelected('no');
        setAllSelected('no');
        $('.date_show_hide').hide();
        fetchData();
    }


    return (
        <body id="page-top">
            {/*  <!-- Page Wrapper --> */}
            <div id="wrapper">
                {/*  <!-- Sidebar --> */}
                <SideMenu />
                {/*  <!-- End of Sidebar --> */}

                {/*  <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/*  <!-- Main Content --> */}
                    <div id="content">
                        {/*  <!-- Topbar --> */}
                        <Header />
                        {/* ...Content Start... */}
                        <div>
                            <div className='container-fluid' style={{ backgroundColor: "#F7F8FA" }}>
                                <div className='card border-0' style={{ backgroundColor: "transparent" }}>
                                    <div className='card-body pt-4 pl-1'>
                                        <h5 className='Roles_text'>Open Stock Report</h5>
                                        <div className='mt-4'>
                                            <div className='d-flex gap-1 status_card'>
                                                <div className='card flex-grow-1' style={{
                                                    border: '1px solid #0000001A',
                                                    boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                                }}>
                                                    <div className='card-body' style={{ padding: "20px 20px" }}>

                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className='col-md-3 col-12'>
                                                                    <label
                                                                        className="form-label font-weight-bold mt-1 mr-1"
                                                                    >Select Date Duration :     </label>

                                                                    <div className="btn-group" id='filter-btn' role="group" aria-label="Basic radio toggle button group" onChange={handleWMYChange}>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={WeekSelected === 'yes'} value="W" />
                                                                        <label className="btn btn-outline-primary" for="btnradio1">Week</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked={monthSelected === 'yes'} value="M" />
                                                                        <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={yearSelected === 'yes'} value="Y" />
                                                                        <label className="btn btn-outline-primary" for="btnradio3">Year</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autocomplete="off" checked={allSelected === 'yes'} value="All" />
                                                                        <label className="btn btn-outline-primary" for="btnradio4">All</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3 col-12'>
                                                                    <label
                                                                        className="form-label font-weight-bold mt-1"
                                                                    >Locations  </label>

                                                                    <select id="dropdown"
                                                                        className="form-control p-2" value={location}
                                                                        onChange={handleChange} name="machineSelecetd">
                                                                        <option value="">Select Location</option>
                                                                        {storeLocationOptions.map((item) => (
                                                                            <option key={item.code} value={item.name} code={item.code}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className='col-md-3 col-12'>
                                                                    <label

                                                                        className="form-label  font-weight-bold mt-1"
                                                                    >Category  </label>

                                                                    <select id="dropdown"
                                                                        className="form-control p-2"
                                                                        onChange={handleProductCategory} name="category" value={productCategory} >
                                                                        <option value={productName}>Select Product Category</option>
                                                                        {productCategoryOptions.map((item) => (
                                                                            <option key={item.code} value={item.name} code={item.code}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className='col-md-3 col-12' style={{ marginBottom: "40px" }}>
                                                                    <label

                                                                        className="form-label  font-weight-bold mt-1"
                                                                    >Products</label>

                                                                    <select id="dropdown"
                                                                        className="form-control p-2" value={productName}
                                                                        onChange={handleChange} name="product">
                                                                        <option value="">Select Product</option>
                                                                        {productNameOptions.map((item) => (
                                                                            <option key={item.code} value={item.name} code={item.code}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div

                                                                    className='col-sm-3 offset-sm-3 col-12 date_show_hide'
                                                                    style={{ display: "none" }}
                                                                >
                                                                    <div class="mb-3">
                                                                        <label
                                                                            for="startdate"
                                                                            className="form-label  font-weight-bold mt-1"
                                                                        >From Date</label>
                                                                        <DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select Start Date"
                                                                            selected={startDate}
                                                                            onChange={(date) => { setStartDate(date) }}
                                                                            maxDate={endDate} dateFormat="dd/MM/yyyy"
                                                                            id="activitystartdate"
                                                                            name="activitystartdate"
                                                                            customInput={<CustomDatePickerInput />}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className='col-sm-3 col-12 date_show_hide'
                                                                    style={{ display: "none", marginBottom: "40px" }}
                                                                >
                                                                    <div class="mb-3">
                                                                        <label
                                                                            className="form-label  font-weight-bold mt-1"
                                                                            for="enddate">To Date</label>
                                                                        <span><DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select End Date" selected={endDate}
                                                                            onChange={(date) => setEndDate(date)} dateFormat="dd/MM/yyyy"
                                                                            minDate={startDate} maxDate={todayDate} id="activityenddate"
                                                                            name="activityenddate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className='mt-2 d-flex w-full justify-content-center gap-2'>
                                                                <button class="btn btn-primary mr-0"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    type='submit'>Apply</button>

                                                                <button class="btn btn-danger"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={resetData}>Clear</button>

                                                                <button

                                                                    className="btn btn-secondary mr-0 buttons-excel buttons-html5"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={Sendmail}>Mail</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='card flex-grow-1 mt-2' style={{
                                            border: '1px solid #0000001A',
                                            boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                        }}>
                                            <div className='card-body' style={{ padding: "10px 10px" }}>

                                                <div className="d-flex justify-content-between align-items-center"
                                                    style={{ paddingLeft: 25, paddingBottom: 20 }}>
                                                    <div className="d-flex align-items-center">
                                                        <label className="mr-2">Show {' '}</label> &nbsp;&nbsp;
                                                        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>

                                                        </select>&nbsp;&nbsp;
                                                        <label>{' '} Entries</label>
                                                    </div>

                                                    <div className="d-flex align-items-center" style={{ marginLeft: '5%' }}>

                                                        {/* Search Input */}
                                                        <input
                                                            type="text"
                                                            placeholder="🔍 Type to Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => handleFilter(e.target.value)}
                                                            style={{ height: "36px", borderRadius: '4px' }}
                                                            className="mr-1 "
                                                        /> &nbsp;
                                                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                                                            <button className="btn btn-secondary buttons-excel buttons-html5"
                                                                onClick={exportToExcel}>
                                                                Download Excel
                                                            </button>

                                                            <button
                                                                className="btn btn-primary"
                                                                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                                                                onClick={handlePrint}
                                                            >
                                                                Print
                                                            </button>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    className="print-table"
                                                    style={{ overflowX: 'auto' }}>
                                                    <Table className="table table-bordered Content_data_table table-striped" >

                                                        <Thead>
                                                            <Tr>
                                                                <Th className='text-center'>S.No</Th>
                                                                <Th className='text-center'>Stock in Date</Th>
                                                                <Th className='text-center'>Location</Th>
                                                                <Th className='text-center'>Category</Th>
                                                                <Th className='text-center'>Product</Th>
                                                                <Th className='text-center'>Available Qty</Th>
                                                                <Th className='text-center'>Issued Qty</Th>
                                                                <Th className='text-center'>Balance Qty</Th>
                                                                <Th className='text-center'>Expired Qty</Th>
                                                            </Tr>
                                                        </Thead>

                                                        <Tbody>
                                                            {currentItems && currentItems.length > 0 ? (
                                                                currentItems.map((item, index) => (
                                                                    <React.Fragment key={indexOfFirstItem + 1 + index}>
                                                                        <Tr>
                                                                            <Td className='text-center' data-id={indexOfFirstItem + 1 + index}>{indexOfFirstItem + 1 + index}</Td>
                                                                            <Td className='text-left'>{item.date}</Td>
                                                                            <Td className='text-left'>{item.location}</Td>
                                                                            <Td className='text-left'>{item.category}</Td>
                                                                            <Td className='text-left'>{item.product}</Td>
                                                                            <Td className='text-left'>{item.availableQuantity}</Td>
                                                                            <Td className='text-left'>{item.issueQuantity}</Td>
                                                                            <Td className='text-left'>{item.balanceQuantity}</Td>
                                                                            <Td className='text-left'>{item.expiredQuantity}</Td>
                                                                        </Tr>
                                                                    </React.Fragment>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="8" className='text-center'>No Data Available</td>
                                                                </tr>
                                                            )}
                                                        </Tbody>

                                                        {isLoading && <Loader loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage} />}

                                                    </Table>
                                                </div>

                                                <div className='py-3'>
                                                    <div className='card data_tableCard'>
                                                        <div className='card-body'>

                                                            {/* Pagination */}

                                                            <div className="d-flex justify-content-between mb-10" style={{ borderRadius: 5, }}>
                                                                <div>
                                                                    Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, totalItems)} of {totalItems} entries
                                                                </div>
                                                                <div>
                                                                    <div className="div-button text-center" >
                                                                        <button
                                                                            className='borderleft-radius-5'
                                                                            // className='ul-pagination'
                                                                            onClick={() => handlePageClick(currentPage - 1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            Previous
                                                                        </button>


                                                                        <button className='ul-pagination page-item'
                                                                            style={{
                                                                                backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                                                                color: (currentPage == 1) ? 'white' : 'black'
                                                                            }}
                                                                            key={1}
                                                                            onClick={() => handlePageClick(1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            {1}

                                                                        </button>
                                                                        {pageNumbers.map((number) => (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == number) ? 'white' : 'black'
                                                                                }}
                                                                                key={number}
                                                                                onClick={() => handlePageClick(number)}
                                                                                disabled={currentPage === number}
                                                                            >
                                                                                {number}
                                                                            </button>
                                                                        ))}
                                                                        {((Math.ceil(totalItems / itemsPerPage)) > 1 && currentPage !== (Math.ceil(totalItems / itemsPerPage) - 1) && currentPage !== (Math.ceil(totalItems / itemsPerPage))) && (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: 'white',
                                                                                    color: 'black'
                                                                                }}
                                                                            >
                                                                                ...
                                                                            </button>
                                                                        )}
                                                                        {(Math.ceil(totalItems / itemsPerPage)) > 1 &&
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                                                                }}
                                                                                key={(Math.ceil(totalItems / itemsPerPage))}
                                                                                onClick={() => handlePageClick((Math.ceil(totalItems / itemsPerPage)))}
                                                                                disabled={currentPage === (Math.ceil(totalItems / itemsPerPage))}
                                                                            >
                                                                                {(Math.ceil(totalItems / itemsPerPage))}

                                                                            </button>
                                                                        }
                                                                        <button className='borderright-radius-5'
                                                                            onClick={() => handlePageClick(currentPage + 1)}
                                                                            disabled={indexOfLastItem >= totalItems}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ...End of Main Content...*/}
                    {/* ...Footer...*/}
                    <Footer />
                    {/* ... End of Footer...  */}
                </div>
            </div>
            {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */}
            <Logout />
            {/* {!isUserLoggedIn && <Login />} */}
        </body>
    )
}

export default OpenStockReport;
