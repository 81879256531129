import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import * as XLSX from "xlsx";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import { MultiSelect } from "react-multi-select-component";
import { CustomDatePickerInput } from "../Utilities/utils";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import "@fortawesome/fontawesome-free/css/all.min.css";


import {
  getMasters,
  FarmOperation_getMasters,
  rmGetmasters,
  reportForRMTracking,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { filterObjects } from "../Utils";
import DatePicker from "react-datepicker";

//ProductMaster

const BulkAllocationReport = ({ }) => {
  const [thCount, setThCount] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [typeOfOperation, setTypeOfOperation] = useState([]);
  const [operations, setOpeartions] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [todayDate, settodayDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [getData, setGetData] = useState([]);
  const [generalWork, setGeneralWork] = useState();
  const [errgeneralWork, setErrGeneralWork] = useState("");
  const [generalList, setGeneralList] = useState([]);
  const [errLocation, setErrLocation] = useState("");
  const [errSubLocation, setErrSubLocation] = useState("");
  const [subLocation, setSubLocation] = useState("");
  const [locationData, setlocationData] = useState("");
  const [sublocationData, setsublocationData] = useState([]);
  const [locatinBasedsubLocation, setlocatinBasedsubLocation] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [selectedSubSite, setSelectedSubSite] = useState("");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermBulk, setSearchTermBulk] = useState("");
  const [searchTermBulkk, setSearchTermBulkk] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [productCategory, setProductCategory] = useState("");
  const [productCategoryID, setProductCategoryID] = useState("");
  const [errorProductCategory, setErrorProductCategory] = useState("");
  const [wmy, setwmy] = useState();
  const [location, setLocation] = useState([]);
  const [subSite, setSubSite] = useState([]);
  const [WeekSelected, setWeekSelected] = useState("no");
  const [monthSelected, setmonthSelected] = useState("no");
  const [yearSelected, setYearSelected] = useState("no");
  const [allSelected, setAllSelected] = useState("yes");
  const [getEquipments, setGetEquipments] = useState([]);
  const [equipmentName, setEquipmentName] = useState("");
  const [errEquipment, setErrEquipment] = useState("");
  const [site, setSite] = useState([]);

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>

          (item.empId &&
            item.empId
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.role &&
            item.role
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.emailId &&
            item.emailId
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.mainFunction &&
            item.mainFunction
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.subFunction &&
            item.subFunction
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.costcenter &&
            item.costcenter
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.reportingManagerName &&
            item.reportingManagerName
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.functionalLead &&
            item.functionalLead
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.date && item.date.includes(searchTerm)) ||
          (item.site &&
            item.site.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.subSite &&
            item.subSite.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.equipment &&
            item.equipment.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.equipmentDescription &&
            item.equipmentDescription
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.location &&
            item.location.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.assignedBy &&
            item.assignedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.assignedTo &&
            item.assignedTo.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.equipmentCondition &&
            item.equipmentCondition
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.equipmentConditionReason &&
            item.equipmentConditionReason
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||

          (item.technicianShiftName
            &&
            item.technicianShiftName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||

          (item.transferTo &&
            item.transferTo.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.workAssignedDate &&
            item.workAssignedDate
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    } else {
      // Handle the case where data is null or undefined
      setFilteredData([]); // For instance, setting filteredData to an empty array
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();
      var maxDate = year + "-" + month + "-" + day;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const tableId = "Content_data_table"; // Replace 'yourTableId' with the actual id of your table
  useEffect(() => {
    const table = document.getElementById(tableId);
    if (table) {
      const theadRow = table.querySelector("thead tr");
      const thCount = theadRow ? theadRow.querySelectorAll("th").length : 0;
      setThCount(thCount);
    } else {
      console.warn(`Table with id '${tableId}' not found.`);
    }
  }, [tableId]); // Run this effect only once when the component mounts

  // const handlePrint = () => {
  //     window.print();
  // };
  const handlePrint = () => {
    const printWindow = window.open();
    const printContent = document.querySelector(".print-table");
    // Check if the printContent is available
    if (printContent) {
      printWindow.document.write(
        "<html><head>" +
        '<meta charset="utf-8" />' +
        '<link rel="icon" href="/favicon.ico" />' +
        '<meta name="viewport" content="width=device-width, initial-scale=1" />' +
        '<meta name="theme-color" content="#000000" />' +
        '<meta name="description" content="Web site created using create-react-app" />' +
        "<title>MCRC</title>" +
        '<link href="css/sb-admin-2.min.css" rel="stylesheet">' +
        '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">' +
        '<script defer src="/static/js/bundle.js"></script>' +
        "<style>" +
        "@media print {" +
        "  table {" +
        "    border-collapse: collapse;" +
        "    width: 100%;" +
        "    margin-bottom: 10px;" +
        "  }" +
        "  th, td {" +
        "    border: 1px solid #ddd;" +
        "    text-align: left;" +
        "  }" +
        "}" +
        "</style>" +
        "</head><body>"
      );

      printWindow.document.write(printContent.innerHTML);
      printWindow.document.write("</body></html>");

      // Delay the print function to ensure styles are applied
      printWindow.setTimeout(() => {
        printWindow.print();
        printWindow.close();
      });
    } else {
      // Handle the case where printContent is not found
      alert("print is not available");
    }
  };

  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Emp-ID",
        "Role",
        "Email-ID",
        "Main Function",
        "Sub Function",
        "Cost Center",
        "Reporting Manager Name",
        "Functional Lead",
        "Date",
        "Site name",
        "Sub Site Name",
        "Eqipment",
        "Eqipment Description",
        "Location",
        "Assigned BY",
        "Assigned To",
        "Equipment Condition",
        "Equipment Condition Reason",
        "Technician ShiftName",
        "Work Completed date",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.empId,
        item.role,
        item.emailId,
        item.mainFunction,
        item.subFunction,
        item.costcenter,
        item.reportingManagerName,
        item.functionalLead,
        item.date,
        item.site,
        item.subSite,
        item.equipment,
        item.equipmentDescription,
        item.location,
        item.assignedBy,
        item.assignedTo,
        item.assignedBy,
        item.equipmentCondition,
        item.equipmentConditionReason,
        item.technicianShiftName,
        item.workAssignedDate,
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    // Save the file
    XLSX.writeFile(wb, "BulkAllocationReport.xlsx");
  };

  const handleSitelList = (selectedSites) => {
    setSite(selectedSites);
    if (selectedSites.length > 0) {
      setErrGeneralWork("");
    }
  }; const siteOptions = Array.isArray(getData.site) ? getData.site : [];


  const handleLocation = async (selectedOptions) => {
    setLocation(selectedOptions);

    if (selectedOptions.length === 0) {
      setlocatinBasedsubLocation([]);
      setErrLocation("Please select at least one location.");
    } else {
      // Convert selected option values to strings
      const selectedCategoryIds = selectedOptions.map((option) => String(option.value));

      // Fetch sub-locations based on the selected category IDs
      const subLocationList = await filterObjects(
        sublocationData,
        "locationId",
        selectedCategoryIds
      );

      if (subLocationList.length > 0) {
        setlocatinBasedsubLocation(subLocationList);
        console.log("Sub-location list:", subLocationList);
      }

      setErrLocation(""); // Clear error
    }
  };
  const locationOptions = getData.bulkUploadLocations?.map((item) => ({
    label: item.name,
    value: String(item.code), // Convert to string to avoid issues
  })) || [];

  const filterObjects = (data, key, values) => {
    if (!Array.isArray(values)) {
      values = [values]; // Ensure values is an array
    }
    return data.filter((item) => values.includes(String(item[key])));
  };



  let commaSeparatedString;
  if (Array.isArray(subSite)) {
    commaSeparatedString = subSite.join(",");
  } else if (typeof subSite === "string") {
    commaSeparatedString = subSite;
  } else {
    commaSeparatedString = "";
  }

  let commaSeparatedStrings;
  if (Array.isArray(equipment)) {
    commaSeparatedStrings = equipment.join(",");
  } else if (typeof equipment === "string") {
    commaSeparatedStrings = equipment;
  } else {
    commaSeparatedStrings = "";
  }

  //subsite
  const handleSubSite = (selectedOptions) => {
    const labelNames = selectedOptions.map((item) => item.label).join(", ");
    console.log("valueArray", labelNames);
    setSubSite(selectedOptions);
    setSelectedSubSite(labelNames);
    // console.log('Selected Sub Site:', JSON.stringify(selectedOptions));
  };

  const handleSearch = (value) => {
    setSearchTermBulk(value);
  };
  // Function to handle the "Select All" checkbox
  const handleSelectAllTypeOfWork = (e) => {
    const checkboxes = document.querySelectorAll(
      '.dropdown-menu input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = e.target.checked;
    });
  };

  //equipment

  const handleEquipment = (selectedOptions) => {
    const labelNames = selectedOptions.map((item) => item.label).join(", ");
    console.log("valueArray", labelNames);
    setEquipment(selectedOptions);
    setEquipmentName(labelNames);
    // console.log('Selected Sub Site:', JSON.stringify(selectedOptions));
  };

  const handleSearchEquip = (value) => {
    setSearchTermBulkk(value);
  };
  const handleWMYChange = (e) => {
    $(".date_show_hide").hide();
    let chk_val = e.target.value;
    setwmy(chk_val);
    if (chk_val === 'M' || chk_val === 'Y' || chk_val === 'W' || chk_val === 'All') {
      setSite([]);
      setLocation([])
      setSubLocation([])
      setSubSite([])
      setEquipment([])
      setStartDate(new Date());
      setEndDate(new Date());
    }
    if (chk_val == "W") {
      setWeekSelected("yes");
      setmonthSelected("no");
      setYearSelected("no");
      setAllSelected("no");
      setwmy("W");
    } else if (chk_val == "M") {
      setWeekSelected("no");
      setmonthSelected("yes");
      setYearSelected("no");
      setAllSelected("no");
      setwmy("M");
    } else if (chk_val == "Y") {
      setWeekSelected("no");
      setmonthSelected("no");
      setYearSelected("yes");
      setAllSelected("no");
      setwmy("Y");
    } else if (chk_val == "All") {
      setWeekSelected("no");
      setmonthSelected("no");
      setYearSelected("no");
      setAllSelected("yes");
      $(".date_show_hide").show();
      setwmy("");
    }
  };

  //for data table

  function extractTimeFromDate(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const timeOnly = dateObject.toLocaleTimeString("en-US", { hour12: true });
    return timeOnly;
  }

  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    // fetchData();
    fetchDataReports();
    getMasterData();
  }, []);
  const getMasterData = async () => {
    const getData = rmGetmasters;
    const headers = await GetApiHeaders();
    try {
      const responseGetData = await axios.get(getData, { headers });
      console.log("resssss,", responseGetData);
      setGetData(responseGetData.data.response);
      setGeneralList(responseGetData.data.response.site);
      setlocationData(responseGetData.data.response.location);
      setsublocationData(responseGetData.data.response.subSite);
      setGetEquipments(responseGetData.data.response.equipment);
    } catch (err) {
      console.log("getError", err);
    }
  };

  const handleProductCategory = async (event) => {
    const selectedCategory = event.target.value;
    const selectedCategoryId =
      event.target.options[event.target.selectedIndex].getAttribute("code");
    console.log("Category", selectedCategory + "--" + selectedCategoryId);
    setProductCategory(selectedCategory);
    setProductCategoryID(selectedCategoryId);
    var subLocationList = await filterObjects(
      sublocationData,
      "productCategoryId",
      selectedCategoryId
    ); // kiran
    if (subLocationList.length > 0) {
      setlocatinBasedsubLocation(subLocationList);
    }
    console.log("=========>", subLocationList);
    if (selectedCategory) {
      setErrorProductCategory("");
    }
  };

  const fetchTypeOfOperationDropdownData = async () => {
    const apiUrl = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("responseLocation", response);
        setTypeOfOperation(response.data.response.subSiteMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  ////Data table api IntigrationN

  const fetchData = async () => {
    // debugger;
    const apiUrl = reportForRMTracking;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // debugger;
        setIsLoading(false);
        setData(response.data.response.rmHistory);
        const respData = response.data.response.rmHistory;
        const activityType = [
          ...new Set(respData.map((item) => item.activityType)),
        ];
        setActivityData(activityType);
        setLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
    // }, 900);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataReports = async () => {
    const fetchApi = reportForRMTracking;
    const headers = await GetApiHeaders();
    const reqObj = {
      WMY: wmy,
      startDate: "",
      endDate: "",
      location: "",
      subLocation: selectedSubSite,
      site: "",
      reportType: "bulkAllocation",
      equipment: "",
    };
    try {
      const response = await axios.post(fetchApi, reqObj, { headers });
      //console.log("update Activity", response.data.response.requests)
      setData(response.data.response.equipmentMonitoring);
    } catch (err) {
      console.log("err123", err);
    }
  };

  const handleSubmit = async (event) => {
    // debugger;
    let formattedStartDate = startDate;
    let formattedEndDate = endDate;

    if (startDate) {
      formattedStartDate = startDate.toISOString().split("T")[0];
    }
    if (endDate) {
      formattedEndDate = endDate.toISOString().split("T")[0];
    }


    const transformedLocation = location.map((item) => item.label).join(", ");
    const transformedSite = site.map((item) => item.label).join(", ");
    const transformedEquipment = equipmentName.split(", ").join(", ");

    event.preventDefault();

    const reqObj = {
      WMY: wmy,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      // location: location,
      location: transformedLocation,
      subLocation: selectedSubSite,
      // site: site,
      site: transformedSite,
      reportType: "bulkAllocation",
      equipment: equipmentName,
    };

    const fetchApi = reportForRMTracking;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    axios.post(fetchApi, reqObj, { headers }).then((response) => {
      if (
        response.data.response == "undefined" ||
        response.data.response == ""
      ) {
        setData([]);
      } else setData(response.data.response.equipmentMonitoring);
      console.log(
        "submitted data is" + response.data.response.equipmentMonitoring
      );
      setIsLoading(false);
    });
  };

  const Sendmail = async (event) => {
    // debugger;
    let formattedStartDate = startDate;
    let formattedEndDate = endDate;

    if (startDate) {
      formattedStartDate = startDate.toISOString().split("T")[0];
    }
    if (endDate) {
      formattedEndDate = endDate.toISOString().split("T")[0];
    }

    event.preventDefault();
    const reqObj = {
      WMY: wmy,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      location: location,
      subLocation: selectedSubSite,
      site: site,
      reportType: "equipmentMonitoring",
      equipment: equipmentName,
    };
    const fetchApi = reportForRMTracking;
    const headers = await GetApiHeaders();
    setIsLoading(true);
    axios.post(fetchApi, reqObj, { headers }).then((response) => {
      setIsLoading(false);
      if (
        response.data.response == "undefined" ||
        response.data.response == ""
      ) {
        setData([]);
      } else {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Your work has been saved.",
          confirmButtonColor: "#3085d6",
        });
      }
    });
  };

  function resetData() {
    setwmy("w");
    setSite([]);
    setSubSite([]);
    setlocatinBasedsubLocation([]);
    setGeneralWork("");
    setLocation([]);
    setSubLocation([]);
    setEquipment([]);
    setStartDate(new Date());
    setEndDate(new Date());
    settodayDate(new Date());
    fetchData();
    window.location.reload()
  }
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };


  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div
                className="container-fluid"
                style={{ backgroundColor: "#F7F8FA" }}
              >
                <div
                  className="card border-0"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="card-body pt-4 pl-1">
                    <h5 className="Roles_text">Bulk Allocation Report</h5>
                    <div className="mt-4">
                      <div className="d-flex gap-1 status_card">
                        <div
                          className="card flex-grow-1"
                          style={{
                            border: "1px solid #0000001A",
                            boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ padding: "20px 20px" }}
                          >
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-3 col-12">
                                  <label className="form-label  font-weight-bold mt-1">
                                    Select Date Duration :{" "}
                                  </label>

                                  <div
                                    className="btn-group"
                                    id="filter-btn"
                                    role="group"
                                    aria-label="Basic radio toggle button group"
                                    onChange={handleWMYChange}
                                  >
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio1"
                                      autocomplete="off"
                                      checked={WeekSelected === "yes"}
                                      value="W"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio1"
                                    >
                                      Week
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio2"
                                      autocomplete="off"
                                      checked={monthSelected === "yes"}
                                      value="M"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio2"
                                    >
                                      Month
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio3"
                                      autocomplete="off"
                                      checked={yearSelected === "yes"}
                                      value="Y"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio3"
                                    >
                                      Year
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio4"
                                      autocomplete="off"
                                      checked={allSelected === "yes"}
                                      value="All"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio4"
                                    >
                                      All
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className="col-md-3 col-12"

                                >
                                  <label className="form-label  font-weight-bold mt-1">
                                    Site{" "}
                                  </label>



                                  <MultiSelect
                                    id="dropdown"
                                    options={siteOptions.map((item) => ({
                                      label: item.name,
                                      value: item.code,
                                    }))}
                                    value={site}
                                    onChange={handleSitelList}
                                    labelledBy="Select Site"
                                    overrideStrings={{
                                      selectSomeItems: "Select Site",
                                    }}
                                  />
                                </div>

                                <div
                                  className="col-md-3 col-12 "
                                // style={{ marginLeft: "auto" }}

                                >
                                  <label className="form-label  font-weight-bold mt-1">
                                    Sub Site
                                  </label>

                                  <MultiSelect
                                    options={sublocationData.map((item) => ({
                                      label: item.name,
                                      value: item.code,
                                    }))}
                                    value={subSite}
                                    onChange={handleSubSite}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems: "Select Sub Site",
                                    }}
                                    renderValue={(selected) => (
                                      <div>
                                        {selected?.map(
                                          (selectedItem, index) => (
                                            <span key={index}>
                                              {selectedItem?.code}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>

                                <div className="col-md-3 col-12">
                                  <label className="form-label  font-weight-bold mt-1">
                                    Location{" "}
                                  </label>


                                  <MultiSelect
                                    options={locationOptions}
                                    value={location} // Selected options
                                    onChange={handleLocation} // Handler function
                                    labelledBy="Select Locations" // Label for accessibility
                                  />

                                </div>

                                <div
                                  className="col-md-3 col-12"
                                  style={{
                                    marginLeft: "25%",

                                  }}
                                >
                                  <label className="form-label  font-weight-bold mt-1">
                                    Equipment
                                  </label>

                                  <MultiSelect
                                    options={getEquipments.map((item) => ({
                                      label: item.name,
                                      value: item.code,
                                    }))}
                                    value={equipment}
                                    onChange={handleEquipment}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems: "Select Sub Site",
                                    }}
                                    renderValue={(selected) => (
                                      <div>
                                        {selected?.map(
                                          (selectedItem, index) => (
                                            <span key={index}>
                                              {selectedItem?.code}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>

                                <div className="col-sm-3 col-12 date_show_hide">
                                  <div class="mb-3">
                                    <label
                                      for="startdate"
                                      className="form-label font-weight-bold mt-1"
                                    >
                                      From Date
                                    </label>
                                    <DatePicker
                                      className="form-control w-100"
                                      placeholderText="Select Start Date"
                                      selected={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                      }}
                                      maxDate={endDate}
                                      dateFormat="dd-MMM-yyyy"
                                      id="activitystartdate"
                                      name="activitystartdate"
                                      customInput={<CustomDatePickerInput />}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-sm-3 col-12 date_show_hide"

                                >
                                  <div class="mb-3">
                                    <label
                                      className="form-label font-weight-bold mt-1"
                                      for="enddate"
                                    >
                                      To Date
                                    </label>
                                    <span>
                                      <DatePicker
                                        className="form-control w-100"
                                        placeholderText="Select End Date"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd-MMM-yyyy"
                                        minDate={startDate}
                                        maxDate={todayDate}
                                        id="activityenddate"
                                        name="activityenddate"
                                        customInput={<CustomDatePickerInput />}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-2 d-flex w-full justify-content-center gap-2">
                                <button
                                  class="btn btn-primary mr-0"
                                  style={{
                                    paddingLeft: "3rem",
                                    paddingRight: "3rem",
                                  }}
                                  type="submit"
                                >
                                  Apply
                                </button>

                                <button
                                  class="btn btn-danger"
                                  style={{
                                    paddingLeft: "3rem",
                                    paddingRight: "3rem",
                                  }}
                                  onClick={resetData}
                                >
                                  Clear
                                </button>

                                <button
                                  disabled
                                  className="btn btn-secondary mr-0 buttons-excel buttons-html5"
                                  style={{
                                    paddingLeft: "3rem",
                                    paddingRight: "3rem",
                                  }}
                                  onClick={Sendmail}
                                >
                                  Mail
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card flex-grow-1 mt-2"
                      style={{
                        border: "1px solid #0000001A",
                        boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ padding: "10px 10px" }}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ paddingLeft: 25, paddingBottom: 20 }}
                        >
                          <div className="d-flex align-items-center">
                            <label className="mr-2">Show </label> &nbsp;&nbsp;
                            <select
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                            &nbsp;&nbsp;
                            <label> Entries</label>
                          </div>

                          <div
                            className="d-flex align-items-center"
                            style={{ marginLeft: "10%" }}
                          >
                            {/* Search Input */}
                            <input
                              type="search"
                              placeholder="🔍 Type to Search..."
                              value={searchTerm}
                              onChange={(e) => handleFilter(e.target.value)}
                              style={{ height: "36px", borderRadius: "4px" }}
                              className="mr-1"
                            />{" "}
                            &nbsp;
                            <div className="my-2 d-flex w-full justify-content-end gap-2">
                              <button
                                className="btn btn-secondary buttons-excel buttons-html5"
                                onClick={exportToExcel}
                              >
                                Download Excel
                              </button>
                              {/* <button
                                className="btn btn-primary"
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                                onClick={handlePrint}
                              >
                                Print
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="print-table"
                          style={{ overflowX: "auto" }}
                        >
                          <Table
                            className="table table-bordered Content_data_table table-striped"
                            id="Content_data_table"
                          >
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>

                                <th className="text-center" onClick={() => handleSort("empId")}>
                                  Employee ID
                                  <i
                                    className={`fa ${sortConfig.key === "empId"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("role")}>
                                  Role
                                  <i
                                    className={`fa ${sortConfig.key === "role"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("emailId")}>
                                  Email-ID
                                  <i
                                    className={`fa ${sortConfig.key === "emailId"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("mainFunction")}>
                                  Main Function
                                  <i
                                    className={`fa ${sortConfig.key === "mainFunction"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("subFunction")}>
                                  Sub Function
                                  <i
                                    className={`fa ${sortConfig.key === "subFunction"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("costcenter")}>
                                  Cost Center
                                  <i
                                    className={`fa ${sortConfig.key === "costcenter"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("reportingManagerName")}>
                                  Reporting Manager-Name
                                  <i
                                    className={`fa ${sortConfig.key === "reportingManagerName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("functionalLead")}>
                                  Functional-Lead
                                  <i
                                    className={`fa ${sortConfig.key === "functionalLead"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </th>


                                <Th className="text-center" onClick={() => handleSort("date")}>
                                  Date{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "date"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>

                                <Th className="text-center" onClick={() => handleSort("site")}>
                                  Site Name{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "site"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("subSite")}>
                                  Sub Site Name{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "subSite"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("equipment")}>
                                  Eqipment{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "equipment"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>

                                <Th className="text-center" onClick={() => handleSort("equipmentDescription")}>
                                  Eqipment Description{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "equipmentDescription"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("location")}>
                                  Location{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "location"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("assignedBy")}>
                                  Assigned BY{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "assignedBy"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("assignedTo")}>
                                  Assigned To{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "assignedTo"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>


                                <Th className="text-center" onClick={() => handleSort("equipmentCondition")}>
                                  Equipment Condition{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "equipmentCondition"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>

                                <Th className="text-center" onClick={() => handleSort("equipmentConditionReason")}>
                                  Equipment Condition Reason{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "equipmentConditionReason"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>


                                <Th className="text-center" onClick={() => handleSort("technicianShiftName")}>
                                  Technician ShiftName{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "technicianShiftName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>


                                <Th className="text-center" onClick={() => handleSort("workAssignedDate")}>
                                  Work Completed Date{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "workAssignedDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                              </Tr>
                            </Thead>

                            <Tbody>
                              {currentItems && currentItems.length > 0 ? (
                                sortedItems.map((item, index) => (
                                  <React.Fragment  >
                                    <Tr>
                                      <Td
                                        className="text-center"
                                      >
                                        {(currentPage - 1) * itemsPerPage + index + 1}
                                      </Td>

                                      <td className='text-left'>{item.empId}</td>
                                      <td className='text-left'>{item.role}</td>
                                      <td className='text-left'>{item.emailId}</td>
                                      <td className='text-left'>{item.mainFunction}</td>
                                      <td className='text-left'>{item.subFunction}</td>
                                      <td className='text-left'>{item.costcenter}</td>
                                      <td className='text-left'>{item.reportingManagerName}</td>
                                      <td className='text-left'>{item.functionalLead}</td>



                                      <Td className="text-center">
                                        {item.date}
                                      </Td>
                                      <Td className="text-center">
                                        {item.site}
                                      </Td>
                                      <Td className="text-center">
                                        {item.subSite}
                                      </Td>
                                      <Td className="text-center">
                                        {item.equipment}
                                      </Td>
                                      <Td className="text-center">
                                        {item.equipmentDescription}
                                      </Td>
                                      <Td className="text-center">
                                        {item.location}
                                      </Td>
                                      <Td className="text-center">
                                        {item.assignedBy}
                                      </Td>
                                      <Td className="text-center">
                                        {item.assignedTo}
                                      </Td>
                                      <Td className="text-center">
                                        {item.equipmentCondition}
                                      </Td>
                                      <Td className="text-center">
                                        {item.equipmentConditionReason}
                                      </Td>

                                      <Td className="text-center">
                                        {item.technicianShiftName}
                                      </Td>

                                      {/* <Td className='text-center'>{item.transferTo}</Td> */}
                                      <Td className="text-center">
                                        {item.workAssignedDate}
                                      </Td>
                                    </Tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <Tr>
                                  <Td colSpan={thCount} className="text-center">
                                    No Data Available
                                  </Td>
                                </Tr>
                              )}
                            </Tbody>


                          </Table>
                        </div>

                        <div className="py-3">
                          <div className="card data_tableCard">
                            <div className="card-body">
                              {/* Pagination */}

                              <div
                                className="d-flex justify-content-between mb-10"
                                style={{ borderRadius: 5 }}
                              >
                                <div>
                                  Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                                  &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                                  of {totalItems} entries
                                </div>
                                <div>
                                  <div className="div-button text-center">
                                    <button
                                      className="borderleft-radius-5"
                                      // className='ul-pagination'
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      Previous
                                    </button>

                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor:
                                          currentPage == 1
                                            ? "#0056b3"
                                            : "white",
                                        color:
                                          currentPage == 1 ? "white" : "black",
                                      }}
                                      key={1}
                                      onClick={() => handlePageClick(1)}
                                      disabled={currentPage === 1}
                                    >
                                      {1}
                                    </button>
                                    {pageNumbers.map((number) => (
                                      <button
                                        className="ul-pagination page-item"
                                        style={{
                                          backgroundColor:
                                            currentPage == number
                                              ? "#0056b3"
                                              : "white",
                                          color:
                                            currentPage == number
                                              ? "white"
                                              : "black",
                                        }}
                                        key={number}
                                        onClick={() => handlePageClick(number)}
                                        disabled={currentPage === number}
                                      >
                                        {number}
                                      </button>
                                    ))}
                                    {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                      currentPage !==
                                      Math.ceil(totalItems / itemsPerPage) -
                                      1 &&
                                      currentPage !==
                                      Math.ceil(
                                        totalItems / itemsPerPage
                                      ) && (
                                        <button
                                          className="ul-pagination page-item"
                                          style={{
                                            backgroundColor: "white",
                                            color: "black",
                                          }}
                                        >
                                          ...
                                        </button>
                                      )}
                                    {Math.ceil(totalItems / itemsPerPage) >
                                      1 && (
                                        <button
                                          className="ul-pagination page-item"
                                          style={{
                                            backgroundColor:
                                              currentPage ==
                                                Math.ceil(totalItems / itemsPerPage)
                                                ? "#0056b3"
                                                : "white",
                                            color:
                                              currentPage ==
                                                Math.ceil(totalItems / itemsPerPage)
                                                ? "white"
                                                : "black",
                                          }}
                                          key={Math.ceil(
                                            totalItems / itemsPerPage
                                          )}
                                          onClick={() =>
                                            handlePageClick(
                                              Math.ceil(totalItems / itemsPerPage)
                                            )
                                          }
                                          disabled={
                                            currentPage ===
                                            Math.ceil(totalItems / itemsPerPage)
                                          }
                                        >
                                          {Math.ceil(totalItems / itemsPerPage)}
                                        </button>
                                      )}
                                    <button
                                      className="borderright-radius-5"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={indexOfLastItem >= totalItems}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {isLoading && (
              <Loader_Bulk
                loading={isLoading}
                message={"Fetching Data. Please Wait..!"}
                loderimage={loaderImage}
              />
            )}
          </div>
          {/* ...End of Main Content...*/}
          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      {/*  <!-- Logout Modal--> */}
      <Logout />
      {/* {!isUserLoggedIn && <Login />} */}
    </body>
  );
};

export default BulkAllocationReport;
