import React, { useState, useRef } from "react";
import { useEffect } from "react";
import "react-time-picker/dist/TimePicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import axios from "axios";
import { GetAllAcceptorList } from "../../Utilities/URLCONSTANTS";


function CorePermitM2People(props) {
    const [selectedInspector, setSelectedInspector] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownError, setDropdownError] = useState(false);
    const [status, setStatus] = useState(
        props.approvalStatus === "Approve" ? true : false
    );
    const [peopleData, setPeopleData] = useState(props.data);
    const days = ["mon", "tues", "wed", "thus", "fri", "sat", "sun"];


    // console.log(props.isPeopleToggleOn, "isPeopleToisPeopleToggleOnisPeopleToggleOnggleOn");
    const handleDateChange = (date) => {
        if (date) {
            const months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];

            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();

            const formattedDate = `${day}-${month}-${year}`;
            setPeopleData((prev) => ({ ...prev, date: formattedDate }));
            console.log(formattedDate);
            setIsOpen(false);
        }
    };

    const CustomDatePickerInput = ({ value, onClick }) => (
        <div>
            <input
                className="form-control w-full"
                type="text"
                value={value}
                onClick={onClick}
                readOnly
                style={{ cursor: "pointer", padding: "5px 10px", background: "white" }}
            />
            <FaCalendar
                style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "13px",
                    top: "10px ",
                }}
                onClick={onClick}
            />
        </div>
    );

    const CustomTimePickerInput = ({ value, onClick }) => (
        <div>
            <input
                className="form-control w-full"
                type="text"
                value={value}
                onClick={onClick}
                readOnly
                style={{ cursor: "pointer", padding: "5px 10px", background: "white" }}
            />
            <FaClock
                style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "13px",
                    top: "10px ",
                }}
                onClick={onClick}
            />
        </div>
    );

    const [time, setTime] = useState(new Date());
    const [SelectTime, SetSelectTime] = useState("");

    const handleTimeChange = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTime(selectedTime);
    };

    //End Time
    const [timeOne, setTimeOne] = useState("");

    const handleTimeChangeOne = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTimeOne(selectedTime);
    };

    //JobSiteInspector
    const [timeTwo, setTimeTwo] = useState("");

    const handleTimeChangeJobsite = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTimeTwo(selectedTime);
    };

    const [acceptorsList, setAcceptorsList] = useState([]);


    useEffect(() => {
        const fetchAcceptorsList = async () => {
            try {
                const response = await axios.get(
                    GetAllAcceptorList,
                );
                if (response.data.statusCode === "200") {
                    setAcceptorsList(response.data.response.acceptorsList || []);
                } else {
                    console.error("Error fetching acceptors list:", response.data.message);
                }
            } catch (error) {
                console.error("API Error:", error);
            }
        };
        fetchAcceptorsList();
    }, []);



    const handleSelectionChange = (e) => {
        const { name, value } = e.target;
        const selectedOption = e.target.selectedOptions[0];
        const dataCode = selectedOption ? selectedOption.dataset.code : null;

        // Store the selected code in sessionStorage
        if (dataCode) {
            sessionStorage.setItem("ACCEPTOR_TRANSFER_TO_USERID", dataCode);
            console.log("Selected code stored in sessionStorage:", dataCode);
        }
        setPeopleData((prev) => {
            const updatedData = {
                ...prev,
                [name]: value,
                code: dataCode, // Ensure `code` is consistent with your state structure
            };
            console.log("Updated peopleData:", updatedData);
            return updatedData;
        });

        console.log(`Selected code for ${name}:`, dataCode);
        if (name === "inspectorName" && value) {
            setDropdownError(false);
        }
    };


    useEffect(() => {
        if (peopleData.onSiteInspection === "yes" && !peopleData.inspectorName) {
            setDropdownError(true);
        } else {
            setDropdownError(false);
        }
    }, [peopleData.onSiteInspection, peopleData.inspectorName]);


    const [timeEnd, setTimeEnd] = useState("");

    const handleTimeChangeJobsiteEnd = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTimeEnd(selectedTime);
    };
    //extendedUse
    const [timeExtend, setTimeExtend] = useState("");
    const handleTimeChangeExtendedUse = (key) => (selectedTime) => {
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );

        setPeopleData((prev) => ({
            ...prev,
            extendedUse: {
                ...prev.extendedUse,
                [key]: formattedTime,
            },
        }));
        setTimeExtend(selectedTime);
    };

    const [timeExtendEnd, setTimeExtendEnd] = useState("");
    const handleTimeChangeExtendedUseEnd = (key) => (selectedTime) => {
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );

        setPeopleData((prev) => ({
            ...prev,
            extendedUse: {
                ...prev.extendedUse,
                [key]: formattedTime,
            },
        }));

        setTimeExtendEnd(selectedTime);
    };

    useEffect(() => {
        setPeopleData(props.data);
        console.log("peopleData::", props.data);
    }, []);

    useEffect(() => {
        props.onDataChange(peopleData);
        console.log("peopleData", JSON.stringify(peopleData));
    }, [peopleData]);

    const currentDate = new Date().toISOString().split("T")[0];
    // const handlePeopleData = (key, val) => {
    //     setPeopleData((prev) => ({ ...prev, [key]: val }));
    // };

    const handlePeopleData = (key, val) => {
        setPeopleData((prev) => {
            const updatedData = { ...prev, [key]: val };
            // Clear the inspectorName when onSiteInspection is set to "no"
            if (key === "onSiteInspection" && val === "no") {
                updatedData.inspectorName = ""; // Clear the inspectorName
            }
            return updatedData;
        });
    };


    const handlePeopleList = (id, val) => {
        const updatedPeopleList = peopleData.peopleList.map((opItem) => ({
            ...opItem,
            checked: opItem.id == id ? val : opItem.checked,
            selectedValue:
                opItem.checked === true ? "yes" : opItem.checked === false ? "no" : "",
        }));
        handlePeopleData("peopleList", updatedPeopleList);
    };

    const handlePeopleExtendedData = (peopleData, key, val) => {
        var extendedUse = peopleData.extendedUse;
        if (
            (key !== "yes" &&
                key !== "na" &&
                val === "true" &&
                extendedUse["yes"] === "") ||
            (key !== "yes" &&
                key !== "na" &&
                val === "true" &&
                extendedUse["na"] === true)
        ) {
            alert("plz check YES");
            return;
        } else {
            if (key == "yes") {
                extendedUse["na"] = "";
            }
            if (key == "na") {
                extendedUse["yes"] = "";
                extendedUse["mon"] = "";
                extendedUse["tues"] = "";
                extendedUse["wed"] = "";
                extendedUse["thus"] = "";
                extendedUse["fri"] = "";
                extendedUse["sat"] = "";
                extendedUse["sun"] = "";
            }
            if (extendedUse["na"] === "true" && key !== "yes") {
                return;
            }
            extendedUse[key] = val;
            var temppeopleData = extendedUse;
            setPeopleData((prev) => ({ ...prev, extendedUse }));
        }
    };

    function calculateMinEndTime(startTime) {
        if (startTime) {
            const selectedTime = new Date(startTime);
            selectedTime.setMinutes(selectedTime.getMinutes() + 1);
            return selectedTime;
        } else {
            return new Date();
        }
    }

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        <div>
                            <div class="form-group">
                                <label>Permit issuer<span style={{ color: "red" }}> *</span></label>
                                <div>
                                    <input
                                        class="form-control mb-1"
                                        placeholder="PRINT"
                                        readOnly={props.approvalStatus}
                                        value={peopleData?.permitIssuer || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            // Use a regular expression to match only alphabets (A-Z and a-z)
                                            const alphabetsOnly = inputValue.replace(
                                                /[^a-zA-Z .]/g,
                                                ""
                                            );
                                            handlePeopleData("permitIssuer", alphabetsOnly);
                                        }}
                                    />
                                    <input
                                        class="form-control"
                                        placeholder="SIGN"
                                        readOnly={props.approvalStatus}
                                        value={peopleData?.permitIssuerSign || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            // Use a regular expression to match only alphabets (A-Z and a-z)
                                            const alphabetsOnly = inputValue.replace(
                                                /[^a-zA-Z .]/g,
                                                ""
                                            );
                                            handlePeopleData("permitIssuerSign", alphabetsOnly);
                                        }}
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Phone number <span style={{ color: "red" }}> *</span></label>
                                <div>
                                    <input
                                        class="form-control"
                                        id="ph"
                                        type="text"
                                        maxLength={10}
                                        readOnly={props.approvalStatus}
                                        value={peopleData?.phoneNumber || ""}
                                        onChange={(e) => {
                                            const inputText = e.target.value;
                                            const sanitizedInput = inputText.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                            const validPhoneNumber = /^[^6789]/.test(sanitizedInput)
                                                ? ""
                                                : sanitizedInput; // Check if it starts with 0, 6, 7, 8, or 9
                                            handlePeopleData("phoneNumber", validPhoneNumber);
                                        }}
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    Plant/Facility <span style={{ color: "red" }}> *</span>
                                </label>
                                <div>
                                    <input
                                        class="form-control col-12"
                                        readOnly={props.approvalStatus}
                                        placeholder=""
                                        value={peopleData?.plantFacility || ""}
                                        // disabled={!props.isPeopleToggleOn}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphanumericOnly = inputValue.replace(
                                                /[^a-zA-Z0-9 .,]/g,
                                                ""
                                            );
                                            handlePeopleData("plantFacility", alphanumericOnly);
                                        }}
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Co-Signature</label>
                                <div>
                                    <input
                                        class="form-control mb-1"
                                        readOnly={props.approvalStatus}
                                        placeholder="PRINT"
                                        value={peopleData?.coSignature || ""}
                                        onChange={(e) =>
                                            handlePeopleData("coSignature", e.target.value)
                                        }
                                    />
                                    <input
                                        class="form-control"
                                        readOnly={props.approvalStatus}
                                        placeholder="SIGN"
                                        value={peopleData?.coSignatureSign || ""}
                                        onChange={(e) =>
                                            handlePeopleData("coSignatureSign", e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Issuer transferred to</label>
                                <div>
                                    <input
                                        class="form-control"
                                        placeholder="PRINT"
                                        value={peopleData?.issueTransferTo || ""}
                                        readOnly={props.approvalStatus}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphabetsOnly = inputValue.replace(
                                                /[^a-zA-Z .]/g,
                                                ""
                                            );
                                            handlePeopleData("issueTransferTo", alphabetsOnly);
                                        }}
                                    />

                                    <input
                                        class="form-control"
                                        readOnly={props.approvalStatus}
                                        placeholder="SIGN"
                                        value={peopleData?.issueTransferToSign || ""}
                                        onChange={(e) =>
                                            handlePeopleData("issueTransferToSign", e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Permit receiver</label>
                                <div>
                                    <input
                                        class="form-control mb-1"
                                        placeholder="PRINT"
                                        readOnly={props.approvalStatus}
                                        value={peopleData?.permitReceiver || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphabetsOnly = inputValue.replace(
                                                /[^a-zA-Z .]/g,
                                                ""
                                            );
                                            handlePeopleData("permitReceiver", alphabetsOnly);
                                        }}
                                    />

                                    <input
                                        class="form-control"
                                        readOnly={props.approvalStatus}
                                        placeholder="SIGN"
                                        value={peopleData?.permitReceiverSign || ""}
                                        onChange={(e) =>
                                            handlePeopleData("permitReceiverSign", e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div class="form-group">
                                    <label>Work limited to the following</label>
                                    <div>
                                        <label>
                                            JOB SCOPE / DESCRIPTION / TASKS & AREA / EQUIPMENT &
                                            BOUNDARIES<span style={{ color: "red" }}> *</span>
                                        </label>
                                        <div>
                                            <input
                                                className="form-control mb-1"
                                                placeholder=""
                                                value={peopleData?.workLimit || ""}
                                                readOnly={props.approvalStatus}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const alphanumericOnly = inputValue.replace(
                                                        /[^a-zA-Z0-9 .,]/g,
                                                        ""
                                                    );
                                                    handlePeopleData("workLimit", alphanumericOnly);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div class="form-group" style={{ display: "none" }}>
                        <label>Phone number</label>
                        <div>
                            <input
                                class="form-control"
                                id="ph"
                                type="text"
                                maxLength={10}
                                readOnly={props.approvalStatus}
                                value={peopleData?.phoneNumber || ""}
                                onChange={(e) => {
                                    const inputText = e.target.value;
                                    const sanitizedInput = inputText.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                    const validPhoneNumber = /^[^6789]/.test(sanitizedInput)
                                        ? ""
                                        : sanitizedInput; // Check if it starts with 0, 6, 7, 8, or 9
                                    handlePeopleData("phoneNumber", validPhoneNumber);
                                }}
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Number of people working under this permit</label>
                        <div>
                            <input
                                className="form-control col-12"
                                value={peopleData?.noOfWorkingPermit || ""}
                                readOnly={props.approvalStatus}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const numericInput = inputValue.replace(/[^0-9]/g, "");
                                    handlePeopleData("noOfWorkingPermit", numericInput);
                                }}
                            />
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Name(s) of people working under this permit</label>
                        <div>
                            <input
                                className="form-control col-12"
                                id="workingPeople"
                                value={peopleData?.namesWorkingPermit || ""}
                                readOnly={props.approvalStatus}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const numericInput = peopleData?.noOfWorkingPermit || 0; // Get the numeric value
                                    const alphabeticAndCommaInput = inputValue.replace(
                                        /[^a-zA-Z, ]/g,
                                        ""
                                    );
                                    const names = alphabeticAndCommaInput
                                        .split(",")
                                        .map((name) => name);
                                    const validNames = names.slice(0, numericInput).join(",");
                                    handlePeopleData("namesWorkingPermit", validNames);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Receiver transferred to</label>
                    <div>
                        <input
                            class="form-control mb-1"
                            placeholder="PRINT"
                            value={peopleData?.receiverTransferTo || ""}
                            readOnly={props.approvalStatus}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Use a regular expression to match only alphabets (A-Z and a-z)
                                const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, "");
                                handlePeopleData("receiverTransferTo", alphabetsOnly);
                            }}
                        />

                        <input
                            class="form-control"
                            placeholder="SIGN"
                            readOnly={props.approvalStatus}
                            value={peopleData?.receiverTransferToSign || ""}
                            onChange={(e) =>
                                handlePeopleData("receiverTransferToSign", e.target.value)
                            }
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>Company</label>
                    <div>
                        <input
                            class="form-control"
                            placeholder="PRINT"
                            readOnly={props.approvalStatus}
                            value={peopleData?.company || ""}
                            onChange={(e) => handlePeopleData("company", e.target.value)}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label>Date</label>
                    <div>
                        <DatePicker
                            readOnly={props.approvalStatus}
                            value={peopleData?.date || ""}
                            onChange={(date) => handleDateChange(date)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Select a Date"
                            showYearDropdown
                            showMonthDropdown
                            customInput={<CustomDatePickerInput />}
                            closeOnSelect
                            minDate={new Date(currentDate)}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>Start time </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            selected={time}
                            readOnly={props.approvalStatus}
                            value={peopleData?.startTime || ""}
                            onChange={handleTimeChange("startTime")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>End time</label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            value={peopleData?.endTime || ""}
                            onChange={handleTimeChangeOne("endTime")}
                            readOnly={props.approvalStatus}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            minTime={calculateMinEndTime(SelectTime)}
                            maxTime={new Date(new Date().setHours(23, 59, 59, 999))} // Set maxTime to the end of the day (11:59 PM)
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>Additional Attachments</label>
                    <div>
                        <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            readOnly={props.approvalStatus}
                            value={peopleData?.additionalAttachment || ""}
                            onChange={(e) =>
                                handlePeopleData("additionalAttachment", e.target.value)
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                {props.data.peopleList.map((item) => (
                    <div class="form-group">
                        <label>
                            {item.id}. {item.question}
                        </label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                disabled={props.approvalStatus}
                                checked={item.checked === "yes"}
                                onChange={() => {
                                    handlePeopleList(
                                        item.id,
                                        item.checked !== "yes" ? "yes" : ""
                                    );
                                }}
                                class="form-check-input"
                                id={`peopleListItemYes${item.id}`}
                            />
                            <label
                                class="form-check-label"
                                for={`peopleListItemYes${item.id}`}
                            >
                                YES
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                disabled={props.approvalStatus}
                                checked={item.checked === "no"}
                                onChange={() => {
                                    handlePeopleList(item.id, item.checked !== "no" ? "no" : "");
                                }}
                                class="form-check-input"
                                id={`peopleListItemNo${item.id}`}
                            />
                            <label
                                class="form-check-label"
                                for={`peopleListItemNo${item.id}`}
                            >
                                NO
                            </label>
                        </div>
                    </div>
                ))}
            </div>
            <div className="form-group">
                <label><strong>On-site inspection required by issuer</strong>
                    {/* <span style={{ color: "red" }}> *</span> */}
                </label>
                <div className="d-flex flex-row m-0 p-0">
                    <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            disabled={props.approvalStatus}
                            checked={peopleData.onSiteInspection === "yes"}
                            onChange={() => {
                                handlePeopleData("onSiteInspection", "yes");
                            }}
                            id="CheckOnsiteYes"
                        />
                        <label className="form-check-label mt-0" htmlFor="CheckOnsiteYes">
                            YES
                        </label>
                    </div>
                    <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                            className="form-check-input mt-0"
                            disabled={props.approvalStatus}
                            type="checkbox"
                            checked={peopleData.onSiteInspection === "no"}
                            onChange={() => {
                                handlePeopleData("onSiteInspection", "no");
                                setSelectedInspector(""); // Reset the dropdown selection when NO is checked
                            }}
                            id="CheckOnsiteNo"
                        />
                        <label className="form-check-label mt-0" htmlFor="CheckOnsiteNo">
                            NO
                        </label>
                    </div>
                </div>
            </div>


            <div className="row">
                <div class="form-group row">
                    <label class="col-9 col-form-label">
                        <strong> Job site inspection required see standard exception
                        </strong>
                        {/* <span style={{ color: "red" }}> *</span> */}
                    </label>
                </div>
                {peopleData.onSiteInspection === "yes" && (
                    <div className="form-group">
                        <label>Job Site Inspector</label>
                        <div>
                            <select
                                className={`form-control mb-1 ${dropdownError ? "is-invalid" : ""}`}
                                name="inspectorName"
                                value={peopleData.inspectorName || ""}
                                onChange={handleSelectionChange}
                                disabled={props.approvalStatus}
                            >
                                <option value="">Select Inspector</option>
                                {acceptorsList.map((acceptor) => (
                                    <option key={acceptor.code} value={acceptor.name} data-code={acceptor.code}>
                                        {acceptor.name}
                                    </option>
                                ))}
                            </select>
                            {dropdownError && (
                                <div className="invalid-feedback">
                                    Inspector is required
                                </div>
                            )}
                        </div>
                    </div>
                )}


                <div class="form-group">
                    <label for="inputTime">Start time </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            readOnly={props.approvalStatus}
                            value={peopleData?.startTimeTwo || ""}
                            onChange={handleTimeChangeJobsite("startTimeTwo")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label for="inputTime">End time </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            readOnly={props.approvalStatus}
                            value={peopleData?.endTimeTwo || ""}
                            onChange={handleTimeChangeJobsiteEnd("endTimeTwo")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            minTime={calculateMinEndTime(timeTwo)}
                            maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div class="form-group row">
                    <label class="col-9 col-form-label">
                        Extended use/additional inspection needed
                    </label>
                    <div class="col-3 mt-1">
                        <div class="form-check form-check-inline d-flex align-items-center">
                            <input
                                class="form-check-input mt-0"
                                type="checkbox"
                                checked={
                                    peopleData.extendedUse.yes === true ||
                                        peopleData.extendedUse.yes === "true"
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    if (peopleData.extendedUse.yes !== "true") {
                                        handlePeopleExtendedData(peopleData, "yes", "true");
                                    }
                                }}
                                id="extendYes"
                                disabled={props.approvalStatus}
                            />
                            <label class="form-check-label mt-0" for="inlineCheckbox1">
                                YES
                            </label>
                        </div>

                        <div class="form-check form-check-inline d-flex align-items-center">
                            <input
                                class="form-check-input mt-0"
                                type="checkbox"
                                //
                                checked={
                                    peopleData.extendedUse.na === true ||
                                        peopleData.extendedUse.na === "true"
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    if (peopleData.extendedUse.na !== "true") {
                                        handlePeopleExtendedData(peopleData, "na", "true");
                                    }
                                }}
                                id="extendNO"
                                disabled={props.approvalStatus}
                            />
                            <label class="form-check-label mt-0" for="inlineCheckbox2">
                                NA
                            </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-6">
                        {days.slice(0, 4).map((day) => (
                            <div
                                key={day}
                                className="form-check form-check-inline d-flex align-items-center"
                            >
                                <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    disabled={props.approvalStatus}
                                    checked={
                                        peopleData.extendedUse[day] === true ||
                                            peopleData.extendedUse[day] === "true"
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        handlePeopleExtendedData(
                                            peopleData,
                                            day,
                                            peopleData.extendedUse[day] === "true" ? "" : "true"
                                        );
                                    }}
                                    id={`${day}Checkbox`}
                                />
                                <label
                                    className="form-check-label mt-0"
                                    htmlFor={`${day}Checkbox`}
                                >
                                    {day.charAt(0).toUpperCase() + day.slice(1)}_
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="col-6">
                        {days.slice(4).map((day) => (
                            <div
                                key={day}
                                className="form-check form-check-inline d-flex align-items-center"
                            >
                                <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    disabled={props.approvalStatus}
                                    checked={
                                        peopleData.extendedUse[day] === true ||
                                            peopleData.extendedUse[day] === "true"
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        handlePeopleExtendedData(
                                            peopleData,
                                            day,
                                            peopleData.extendedUse[day] === "true" ? "" : "true"
                                        );
                                    }}
                                    id={`${day}Checkbox`}
                                />
                                <label
                                    className="form-check-label mt-0"
                                    htmlFor={`${day}Checkbox`}
                                >
                                    {day.charAt(0).toUpperCase() + day.slice(1)}_
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div class="form-group">
                    <label>Start time</label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            // showIcon
                            value={peopleData.extendedUse.startTimeOne || ""}
                            readOnly={props.approvalStatus}
                            onChange={handleTimeChangeExtendedUse("startTimeOne")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>End time </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            value={peopleData.extendedUse.endTimeOne || ""}
                            readOnly={props.approvalStatus}
                            onChange={handleTimeChangeExtendedUseEnd("endTimeOne")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            minTime={calculateMinEndTime(timeExtend)}
                            maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CorePermitM2People;
