import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import { strings } from "../Utilities/strings";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./Logout";
//for fontawesome icons

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";


import {
  addMainStoreMaster,
  getMainStoreMaster,
} from "../Utilities/URLCONSTANTS";
import Loader from "../Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import * as XLSX from "xlsx";

// Main Store Master

const MainStoreMaster = ({ }) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [remarks, setRemarks] = useState("");
  const [storeLocation, setStoreLocation] = useState("");
  // const [userId, setUserId] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactMobileNumber, setContactMobileNumber] = useState("");
  const [status, setStatus] = useState("");
  const [Id, setId] = useState(0);
  const [recordExists, setRecordExists] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [buttonText, setButtonText] = useState(strings.recordSubmitted);
  const [showPopup, setShowPopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [errorName, setErrorName] = useState("");
  const [errorStoreLocation, setErrorStoreLocation] = useState("");
  // const [errorUserId, setErrorUserId] = useState('');
  const [errorContactPerson, setErrorContactPerson] = useState("");
  const [errorMobileNumber, setErrorMobileNumber] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [errorRemarks, setErrorRemarks] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [modalData, setModalData] = useState({
    Id: "",
    name: "",
    storeLocation: "",
    userId: "",
    contactPerson: "",
    contactMobileNumber: "",
    remarks: "",
    status: "",
  });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.storeLocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.contactPerson.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.contactMobileNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Name",
        "store Location",
        "contact Person",
        "contact Mobile Number",
        "Status",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item) => [
        item.id,
        item.name,
        item.storeLocation,
        item.contactPerson,
        item.contactMobileNumber,
        item.status === "1" ? "Active" : "InActive",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "MainStoreMaster.xlsx");
  };

  // ...more items

  const handleNameChange = (e) => {
    const input = e.target.value;

    if (input.length > 0) {
      setErrorName("");
    }

    const result = input.replace(/[^a-z_\s]/gi, "");

    // setModalData.name(result)

    setName(result);
  };

  const handleContactPersonChange = (e) => {
    const contactPerson = e.target.value;

    if (contactPerson.length > 0) {
      setErrorContactPerson("");
    }
    const result = contactPerson.replace(/[^a-z_\s]/gi, "");
    setContactPerson(result);
  };

  const handleStoreLocationChange = (e) => {
    if (storeLocation.length > 0) {
      setErrorStoreLocation("");
    }
    setStoreLocation(e.target.value);
  };

  const handleMobileNbr = (e) => {
    const input = e.target.value;

    let result = input.replace(/[^0-9]/g, ""); // Remove non-digit characters

    if (contactMobileNumber.length > 0) {
      setErrorMobileNumber("");
    }
    if (result.length > 10) {
      result = result.substr(0, 10);
    }
    if (input.length < 10) {
      setErrorMobileNumber("Mobile Number is less then 10");
    }

    if (/^[6-9]/.test(result)) {

      setContactMobileNumber(result);

    } else {
      setContactMobileNumber("");
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value) {
      setErrorStatus("");
    }
  };

  const handleRemarks = (e) => {
    if (remarks.length > 0) {
      setErrorRemarks("");
    }
    setRemarks(e.target.value);
  };

  const handleSubmit = () => {
    if (name === "") {
      setErrorName("Please Enter a Name");
    }

    if (storeLocation === "") {
      setErrorStoreLocation("Please Enter StoreLocation");
    }

    if (contactPerson === "") {
      setErrorContactPerson("Please Enter Contact Person");
    }
    if (contactMobileNumber === "") {
      setErrorMobileNumber("Please Enter   Mobile Numbers");
    }

    if (status === "") {
      setErrorStatus("Please Enter status");
    }

    if (recordExists) {
      setRecordExists("Record already exists.");
      return; // Stop further processing if the record exists
    }

    if (
      name !== "" &&
      storeLocation !== "" &&
      contactPerson !== "" &&
      contactMobileNumber !== "" &&
      status !== ""
    ) {
      if (Id == 0) {
        setIsSubmitted(true);
        handlePostData();
        setErrorName("");
        setErrorRemarks("");
        setErrorStoreLocation("");
        setErrorContactPerson("");
        setErrorMobileNumber("");
        setErrorStatus("");
      } else {
        setIsSubmitted(false);
        handlePostData();
        setIsUpdated(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    //setIsLoading(true);
    const apiUrl = getMainStoreMaster;

    const headers = await GetApiHeaders();
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(apiUrl, { headers })
        .then((response) => {
          console.log("response", response);
          setData(response.data.response.MainStore);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        });
    }, 900);
  };

  const handlePostData = async () => {
    const apiUrl = addMainStoreMaster;
    const headers = await GetApiHeaders();
    const postData = {
      Id: Id == 0 ? "" : Id.toString(),
      name: name,
      storeLocation: storeLocation,
      userID: headers.userId,
      contactPerson: contactPerson,
      contactMobileNumber: contactMobileNumber,
      status: status, // 0 means Inactive, 1 means Active
    };

    console.log("request with api call", apiUrl);

    console.log("request with api call", postData);
    setIsLoading(true);
    // Make the POST request using Axios
    axios
      .post(apiUrl, postData, { headers })
      .then((response) => {
        setsubmitResponse(response.data.response);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);

        // Handle the successful response here
        console.log("submit Response:", response);
      })
      .catch((error) => {
        setIsLoading(false);
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  //for data table

  const tableRef = useRef(null);

  useEffect(() => {
    // Check if DataTable is already initialized

    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance

      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable

    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
  }, []);

  ///modal popup

  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setErrorName("");
    setErrorRemarks("");
    setShowModal(true);
    setId(0);
    setName("");
    setStoreLocation("");
    // setUserId("");
    setContactPerson("");
    setContactMobileNumber("");
    setStatus("");
    setModalData({
      Id: "",
      name: "",
      storeLocation: "",
      // userId: '',
      contactPerson: "",
      contactMobileNumber: "",
      remarks: "",
      status: "",
    });
  };

  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowWarningPopup(false);
    setShowMsg(true);
  };

  const handleCancel_new = () => {
    setShowModal(true);
    setShowPopup(true);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const updatedItems = (item) => {
    // setModalData(item)
    setId(item.id);
    setName(item.name);
    setStoreLocation(item.storeLocation);
    // setUserId(item.userId);
    setContactPerson(item.contactPerson);
    setContactMobileNumber(item.contactMobileNumber);
    setStatus(item.status);
    setRemarks(item.remarks);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}

      <div id="wrapper">
        {/*  <!-- Sidebar --> */}

        <SideMenu />

        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}

        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}

          <div id="content">
            {/*  <!-- Topbar --> */}

            <Header />

            {/* ...Content Start... */}

            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Masters</h5>

                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text">
                          Main Store Master
                        </h6>
                      </div>

                      <span
                        className="d-inline-block"
                        tabIndex="0"
                        data-toggle="tooltip"
                        title="Add Store Master"
                      >
                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>

                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingLeft: 25, paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          {/* Add more options as needed */}
                        </select>
                        &nbsp;&nbsp;
                        <label> Entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="🔍 Type to Search..."
                            value={searchTerm}
                            onChange={(e) => handleFilter(e.target.value)}
                            style={{
                              height: "36px",
                              width: "160px",
                              paddingRight: "30px",
                            }}
                            className="mr-2"
                          />
                          {searchTerm && (
                            <button
                              className="btn btn-link"
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                zIndex: 1,
                              }}
                              onClick={() => handleFilter("")}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          )}
                        </div>
                        &nbsp;
                        {/* Add the Excel export button */}
                        <button
                          className="btn btn-secondary buttons-excel buttons-html5"
                          onClick={exportToExcel}
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>

                    <div>
                      <div className="card data_tableCard">
                        <div
                          className="card-body"
                          style={{ overflowX: "auto" }}
                        >
                          <Table className="table table-bordered Content_data_table table-striped">
                            {/* <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>
                                <Th className="text-center">Name</Th>
                                <Th className="text-center">Store Location</Th>
                                <Th className="text-center">Contact Person</Th>
                                <Th className="text-center">
                                  Contact Mobile Number
                                </Th>
                                <Th className="text-center">Status</Th>
                                <Th className="text-center">Actions</Th>
                              </Tr>
                            </Thead> */}

                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>
                                <Th className="text-center" onClick={() => handleSort("name")}>
                                  Name
                                  <i
                                    className={`fa ${sortConfig.key === "name"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("storeLocation")}>
                                  Store Location
                                  <i
                                    className={`fa ${sortConfig.key === "storeLocation"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("contactPerson")}>
                                  Contact Person
                                  <i
                                    className={`fa ${sortConfig.key === "contactPerson"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("contactMobileNumber")}>
                                  Contact Mobile Number
                                  <i
                                    className={`fa ${sortConfig.key === "contactMobileNumber"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("status")}>
                                  Status
                                  <i
                                    className={`fa ${sortConfig.key === "status"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("actions")}>
                                  Actions
                                  <i
                                    className={`fa ${sortConfig.key === "actions"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                              </Tr>
                            </Thead>

                            <Tbody>
                              {sortedItems.length === 0 ? ( // Check if no data is found
                                <Tr>
                                  <Td
                                    colSpan={19}
                                    className="text-center"
                                    style={{ padding: 195, fontSize: 18 }}
                                  >
                                    Data is not available
                                  </Td>
                                </Tr>
                              ) : (
                                currentItems != undefined &&
                                currentItems != null &&
                                sortedItems.map((item, i) => (
                                  // console.log("item", i);
                                  // return (
                                  <Tr key={i + 1}>
                                    <Td className="text-left">{(currentPage - 1) * itemsPerPage + i + 1}</Td>
                                    <Td>{item.name}</Td>
                                    <Td>{item.storeLocation}</Td>
                                    <Td>{item.contactPerson}</Td>
                                    <Td className="text-right">
                                      {item.contactMobileNumber}
                                    </Td>
                                    <Td className="text-center">
                                      {item.status === "1" ? (
                                        <span style={{ color: "#2CA800" }}>
                                          Active
                                        </span>
                                      ) : (
                                        <span style={{ color: "#FF0000" }}>
                                          In Active
                                        </span>
                                      )}
                                    </Td>
                                    <Td className="text-center">
                                      <div
                                        onClick={() => updatedItems(item, i)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="plus_icon mr-1 text-primary"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit store"
                                        />
                                        {/* Add button content here */}
                                      </div>
                                    </Td>
                                  </Tr>
                                  // );}
                                ))
                              )}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                        </div>
                        {/* Pagination */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5 }}
                        >
                          <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                            to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp; of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${currentPage === 1 ? "active" : ""
                                  }`}
                                //  className='borderleft-radius-5'
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button
                                //  className='ul-pagination page-item'
                                //   style={{
                                //     backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                //     color: (currentPage == 1) ? 'white' : 'black'
                                //   }}
                                className={`ul-pagination page-item ${currentPage === 1 ? "active" : ""
                                  }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${currentPage === number ? "active" : ""
                                    }`}
                                  //className='ul-pagination page-item'
                                  //   style={{
                                  //     backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                  //     color: (currentPage == number) ? 'white' : 'black'
                                  //   }}
                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                    }`}
                                  // className='ul-pagination page-item'
                                  // style={{
                                  //   backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                  //   color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                  // }}
                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                  ? "active"
                                  : ""
                                  }`}
                                //className='borderright-radius-5'
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>

                              <Button
                                variant="link"
                                className="close btn btn-danger  close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">
                                  Main Store Master
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-md font-weight-600 mb-0"
                                  >
                                    Main Store Master Name
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Main Store Master Name"
                                      value={name}
                                      onChange={handleNameChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorName}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputText"
                                    className="form-label text-md font-weight-600 mb-0"
                                  >
                                    Store Location
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Store Location"
                                      value={storeLocation}
                                      onChange={handleStoreLocationChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorStoreLocation}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputUserName"
                                    className="form-label text-md font-weight-600 mb-0"
                                  >
                                    Contact Person
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Contact Person"
                                      value={contactPerson}
                                      inputMode="text"
                                      onChange={handleContactPersonChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorContactPerson}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="Inputnumber"
                                    className="form-label text-md font-weight-600 mb-0"
                                  >
                                    Contact Mobile Number
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Mobile Number"
                                      value={contactMobileNumber}
                                      onChange={handleMobileNbr}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorMobileNumber}
                                  </span>
                                </div>

                                {/* <div className='col-md-6 col-12'>

                                  <label for="InputUserName" className="form-label text-md font-weight-600"

                                  >Remarks</label>

                                  <div className="input-group input-group w-100">

                                    <textarea type="text" className="form-control p-2" placeholder='Remarks' value={remarks} onChange={handleRemarks} />

                                  </div>
                                  <span style={{ color: 'red' }}>{errorRemarks}</span>

                                </div> */}

                                <div className="col-md-6 mt-2">
                                  <label
                                    for="Input"
                                    class="form-label text-md font-weight-600 mb-0"
                                  >
                                    Status
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={status}
                                      onChange={handleStatus}
                                    >
                                      <option value="" disabled selected>
                                        Select Status
                                      </option>
                                      <option value="1">Active</option>
                                      <option value="0">In Active</option>
                                    </select>
                                  </div>

                                  <span style={{ color: "red" }}>
                                    {errorStatus}
                                  </span>
                                </div>
                              </div>
                              &nbsp;
                              <div className="row">
                                {isSubmitted ? (
                                  <p style={{ color: "green" }}>
                                    {strings.recordSubmitted}.
                                  </p>
                                ) : null}

                                {isUpdated ? (
                                  <p style={{ color: "green" }}>
                                    {strings.updatedSuccessfully}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center col-md-12">
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => handleSubmit()}
                                >
                                  {/* // onClick={() => handleSubmit({ */}

                                  {/* //   id, name, contactPerson,remarks, */}

                                  {/* //   storeLocation, contactMobileNumber, status */}

                                  {/* // })}> */}

                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>

                                <Button
                                  onClick={handleCancel_new}
                                  className="btnn btn btn-danger"
                                >
                                  Cancel
                                </Button>
                              </div>
                              {recordExists && <p>{recordExists}</p>}
                            </Modal.Body>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  {" "}
                                  Main Store Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close Window ?
                                  </p>
                                ) : null}
                              </div>

                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showWarningPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  {" "}
                                  Main Store Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  MainStore Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {loading &&
                  <div>
                    <p>Fetching data. Please wait...</p>
                  
                    <Loader
                      height="80"
                      width="80"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                    />
                  </div>
                } */}
              </div>
            </div>
          </div>

          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}

          <Footer />

          {/* ... End of Footer...  */}
        </div>
      </div>

      {/*  <!-- End of Page Wrapper -->


                    <!-- Scroll to Top Button--> */}

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}

      <Logout />
    </body>
  );
};

export default MainStoreMaster;
